import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    cartData: [],
    totalAmount: 0,
    totalQuantity: 0, // Add totalQuantity to the initial state
  };
  
  const CartSlice = createSlice({
    name: "CartSlice",
    initialState,
    reducers: {
      addToCart: (state, action) => {
  const { cartData, totalQuantity } = state;
  const { _id, price, number_item } = action.payload;
  const itemPresentIndex = cartData.findIndex((item) => item._id === _id);
  const updatedTotalQuantity = totalQuantity + number_item;

  if (itemPresentIndex !== -1) {
    const updatedCartData = [...cartData];
    updatedCartData[itemPresentIndex] = {
      ...updatedCartData[itemPresentIndex],
      number_item: updatedCartData[itemPresentIndex].number_item + number_item,
    };
    const total = updatedCartData.reduce(
      (accu, currentItem) => accu + currentItem.price * currentItem.number_item,
      0
    );
    return {
      ...state,
      cartData: updatedCartData,
      totalAmount: total,
      totalQuantity: updatedTotalQuantity,
    };
  } else {
    const finalData = [
      { ...action.payload, number_item: number_item },
      ...cartData,
    ];
    const total = finalData.reduce(
      (accu, currentItem) => accu + currentItem.price * currentItem.number_item,
      0
    );
    return {
      ...state,
      cartData: finalData,
      totalAmount: total,
      totalQuantity: updatedTotalQuantity,
    };
  }
},
      removeFromCart: (state, action) => {
        const { cartData, totalQuantity } = state;
        const itemPresentIndex = cartData.findIndex(
          (item) => item._id === action.payload
        );
        const updatedTotalQuantity =
          totalQuantity > 0 ? totalQuantity - 1 : totalQuantity;
  
        if (itemPresentIndex !== -1) {
          const updatedCartData = [...cartData];
          if (updatedCartData[itemPresentIndex].number_item > 1) {
            updatedCartData[itemPresentIndex] = {
              ...updatedCartData[itemPresentIndex],
              number_item: updatedCartData[itemPresentIndex].number_item - 1,
            };
          } else {
            updatedCartData.splice(itemPresentIndex, 1);
          }
  
          const total = updatedCartData.reduce(
            (accu, currentItem) =>
              accu + currentItem.price * currentItem.number_item,
            0
          );
          return {
            ...state,
            cartData: updatedCartData,
            totalAmount: total,
            totalQuantity: updatedTotalQuantity,
          };
        }
      },
  
      deleteFromCart: (state, action) => {
        const { cartData } = state;
        const itemIdToRemove = action.payload;
      
        const updatedCartData = cartData
          .map((item) => (item._id === itemIdToRemove ? { ...item, number_item: 0 } : item))
          .filter((item) => item.number_item > 0);
      
        const total = updatedCartData.reduce(
          (accu, currentItem) => accu + currentItem.price * currentItem.number_item,
          0
        );

        return {
          ...state,
          cartData: updatedCartData,
          totalAmount: total,
          totalQuantity: updatedCartData.length,
        };
      },
      
  
      clearCart: () => {
        return initialState;
      },
    },
  });
  
  

export const { addToCart, removeFromCart, clearCart, deleteFromCart, calculateTotalQuantity } = CartSlice.actions;

export default CartSlice.reducer;
