import React from 'react';
import { Modal, Carousel, Button } from 'antd';
import '../App.css'
import { LeftOutlined, RightOutlined } from '@ant-design/icons';

const CustomSlider = ({ images, visible, onClose }) => {
  const [currentImageIndex, setCurrentImageIndex] = React.useState(0);

  const handleNext = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const handlePrev = () => {
    setCurrentImageIndex((prevIndex) => (prevIndex + images.length - 1) % images.length);
  };
  return (
    <Modal
    open={visible}
    footer={null}
    onCancel={onClose}
    width="80%"
    style={{ padding: 0}}
    centered
    destroyOnClose
  >
    <Carousel
      autoplay={false}
      draggable={true}
      arrows
      style={{height: '65vh'}}
      prevArrow={<Button className="arrow-btn"  onClick={handlePrev} />}
      nextArrow={<Button className="arrow-btn"  onClick={handleNext} />}
      dots={false}
      initialSlide={currentImageIndex}
      afterChange={(current) => setCurrentImageIndex(current)}
    >
      {images.map((media, index) => (
        <div key={index}>
          {media.type === 'image' ? (
            <img
              src={media.src}
              alt={`image-${index}`}
              style={{ width: '100%', maxHeight: '65vh', objectFit: 'contain' }}
            />
          ) : (
            <video controls style={{ width: '100%', maxHeight: '65vh' }}>
              <source src={media.src} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          )}
        </div>
      ))}
    </Carousel>
  </Modal>
  );
};

export default CustomSlider;