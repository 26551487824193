import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    favProducts:[],
    totalQuantity: 0,
};

 const favSclice = createSlice({
  name: "favSclice",
  initialState,
  //actions
  reducers: {
    addToFav:(state, action)=>{
        const { favProducts } = state;
        return {
          ...state,
          favProducts: [...favProducts, action.payload],
          totalQuantity: state.totalQuantity + 1,
        };

      },
      removeFromFav: (state, action) => {
        const { favProducts } = state;
        const idToRemove = action.payload;
        const updatedFavProducts = favProducts.filter(
          (product) => product._id !== idToRemove
        );
        return {
          ...state,
          favProducts: updatedFavProducts,
          totalQuantity: state.totalQuantity - 1,
        };
      },

      clearFav:()=>{
        return initialState;
      }
    
  },
});

export const { addToFav, removeFromFav, clearFav } = favSclice.actions;

export default favSclice.reducer;
