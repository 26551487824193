import React,{useEffect, useState} from "react";
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBCardImage,
  MDBIcon,
  MDBRipple,
  MDBBtn,
} from "mdb-react-ui-kit";
import '../App.css';
import { useDispatch, useSelector } from "react-redux";
import { removeFromFav, addToFav, clearFav } from "../redux/counter/FavSclice";
import { Button, Empty, message, Spin, Typography } from 'antd';
import { display } from "@mui/system";
import { addToCart } from "../redux/counter/CartSlice";
import Theme from "../utils/Theme";
import { API } from "../api";
import { useNavigate } from "react-router";
export const MyFavourites = () => {
  const navigate = useNavigate()
const [favProducts, setFavProducts] = useState(null);
const dispatch = useDispatch();
    const {userData} = useSelector(state=> state.auth);
    const [isLoading, setisLoading] = useState(true)
    //const {favProducts} = useSelector(state=> state.favItems);
 async function getWishlist (){
    const res = await API.get(`wishlist/list?customer_id=${userData?.data?._id}`)
    if(res.data.status){
 setisLoading(false)
    }
    setFavProducts(res.data.data)
    
  }
useEffect(() => {
  getWishlist()
//dispatch(clearFav())
}, [])

   
    
   async function removeFav(ele){
         
      try {
        const res = await API.delete(`wishlist?wishlist_id=${ele._id}`)
        if(res.data.success){
          setFavProducts((prevFavProducts) =>
          prevFavProducts.filter((item) => item._id !== ele._id)
        );
        }
        dispatch(removeFromFav(ele.item_id))
      } catch (error) {
        
      }
      
    }

  //  async function add(ele){
  //     const updatedEle = { ...ele, _id: ele.item_id , quantity: 1};
  //     dispatch(addToCart(updatedEle))
  //     try {
  //       const res = await API.delete(`wishlist?wishlist_id=${ele._id}`)
  //       if(res.data.success){
  //         setFavProducts((prevFavProducts) =>
  //         prevFavProducts.filter((item) => item._id !== ele._id)
  //       );
  //       }
  //       dispatch(removeFromFav(ele.item_id))
  //     } catch (error) {
        
  //     }
  //     message.success(`${ele.title} has been sucessfully Added`)
  //   }

  function viewCard(ele){
    navigate(`/productDetail/${ele.item_id}`)
  }
  return (
    <>
    {isLoading ? <div style={{height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center'}}><Spin tip={'Loading...'} size="large"/></div>  :  favProducts && favProducts?.length > 0 ? <MDBContainer fluid style={{minHeight: '90vh',}}>
   <Typography.Title level={2} style={{textAlign:'center',paddingTop: 20, fontFamily: Theme.customFont.custFontNormal}}>Your Favourites</Typography.Title>
   {favProducts?.map((ele)=>{
    return  <MDBRow key={ele?._id} className="justify-content-center mb-0">
    <MDBCol md="12" xl="10">
      <MDBCard className="shadow-0 border rounded-3 mt-5 mb-3">
        <MDBCardBody>
          <MDBRow>
            <MDBCol md="12" lg="3" className="mb-4 mb-lg-0">
              <MDBRipple
                rippleColor="light"
                rippleTag="div"
                className="bg-image rounded hover-zoom hover-overlay"
              >
                <MDBCardImage
                  src={ele?.thumbnail}
                  fluid
                  className="w-100"
                  style={{height: 200,
                    objectFit: 'cover'}}
                />
              
              </MDBRipple>
            </MDBCol>
            <MDBCol md="6" style={{display:'flex', flexDirection: 'column', justifyContent:'center'}}>
              <h5 style={{fontFamily: Theme.customFont.custFontNormal}}>{ele?.title}</h5>
             
              <p className=" mb-4 mb-md-0" style={{fontFamily: Theme.customFont.custFontNormal}}>
                {ele?.description}
              </p>
            </MDBCol>
            <MDBCol
              md="6"
              lg="3"
              className="border-sm-start-none border-start"
            >
              <div className="d-flex flex-row align-items-center mb-1">
                <h4 className="mb-1 me-1" style={{fontFamily: Theme.customFont.custFontNormal}}>£{ele.price}</h4>
                
              </div>
              <h6 className="text-success" style={{fontFamily: Theme.customFont.custFontNormal}}>Free shipping</h6>
              <div className="d-flex flex-column mt-4">
                <MDBBtn onClick={()=> viewCard(ele)} color="danger" size="sm" style={{fontWeight: 600, fontFamily: Theme.customFont.custFontNormal}}>
                  View Card
                </MDBBtn>
                <MDBBtn style={{fontWeight: 600, fontFamily: Theme.customFont.custFontNormal}} onClick={()=> removeFav(ele)} outline color="danger" size="sm" className="mt-2">
                  Remove From Favourites
                </MDBBtn>
              </div>
            </MDBCol>
          </MDBRow>
        </MDBCardBody>
      </MDBCard>
    </MDBCol>
  </MDBRow>
   })}

  </MDBContainer>   : <Empty style={{height: '90vh', alignItems:'center', display:'flex', justifyContent:'center' }}
      image={require('../assets/images/empty_wishlist_new.png')}
      imageStyle={{
        height: '40vh',
      }}
      description={
        <span>
          
        </span>
      }
    >
      
    </Empty>}
    </>
  );
};
