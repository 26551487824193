import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import { persistReducer, persistStore } from "redux-persist";
import storage from 'redux-persist/lib/storage'
import AuthSlice from "./counter/AuthSlice";
import CartSlice from "./counter/CartSlice";
import counterSlice  from "./counter/CounterSlice";
import FavSclice from "./counter/FavSclice";
import ProductSlice from "./counter/ProductSlice";
import ProfileSlice from "./counter/ProfileSlice";


const persistConfig = {
  key: "root",
  storage,
};

const reducers = combineReducers({
  counter: counterSlice,
  auth: AuthSlice,
  products: ProductSlice,
  cartItems: CartSlice, 
  favItems: FavSclice,
  userProfile: ProfileSlice,
});

const persistedReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
  reducer: persistedReducer,
});

export const persistor = persistStore(store);
