import "./App.css";
import { Row, Col, Badge, Layout, Button } from "antd";
import { ShoppingOutlined } from "@ant-design/icons";
import { Footer } from "./components/Footer";
import { BrowserRouter, Link, useNavigate } from "react-router-dom";
import { AppRoutes } from "./routes";
import ScrollToTop from "./components/ScrollToTop";
import { useSelector } from "react-redux";


import UserProfileDropdown from "./components/UserProfileDropdown";

function App() {
  //const count = useSelector((state)=> state.counter);
  const {totalQuantity, cartData} = useSelector(state=> state.cartItems);
  const {userData} = useSelector(state=> state.auth);
  return (
    <div style={{ width: "100%", margin: 0, padding: 0, overflow : "hidden" }}>
      
       
      <BrowserRouter>
      <Row style={{ height: "60px",backgroundColor: '#000'}}>
       <Col span={6} style={{paddingLeft: 20, paddingTop: 10}}>
    {/* Logo */}
   <a href="/"> <img src={require('./assets/images/logo.jpeg')} alt="Logo" style={{ height: 40, width: 40, borderRadius: '50%', borderColor:'transparent' }} /> </a>
  </Col>
        <Col
          span={18}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            paddingRight: 20,
            paddingTop: 5
          }}
        >
         <Link style={{paddingRight: 5, paddingTop: 4}} to={'/addtocart'} ><Badge size='small'  showZero count={cartData?.length} className="shopingCartIcon">
            <img alt='cart' src={require('./assets/images/troll111.png')}  style={{ width:42,height:42, objectFit: 'cover'}} tintColor='#fff' />
          </Badge></Link> 
         {userData ? <UserProfileDropdown /> : <Button type="primary" ghost style={{color: '#fff'}} href='/login'>
      Login
    </Button>} 
        </Col>
      </Row>
      <ScrollToTop />
      <AppRoutes/>
      <Footer />
      </BrowserRouter>
      
    </div>
  );
}

export default App;
