import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    profileData: null,
};

 const profileSclice = createSlice({
  name: "profileSclice",
  initialState,
  //actions
  reducers: {
    setProfileData: (state, action) => {
        state.profileData = action.payload;
      },

      clearProfile: (state) => {
        return initialState;
      }
  },
});

export const { setProfileData, clearProfile } = profileSclice.actions;

export default profileSclice.reducer;
