import React, {useState, useEffect} from "react";
import { Result, Button } from "antd";
import { useNavigate } from "react-router";
import { useSpring, animated } from "react-spring";
import Theme from "../utils/Theme";

const AnimatedResult = animated(Result);

const CancelPage = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);

  const resultAnimation = useSpring({
    opacity: isLoading ? 0 : 1,
    transform: isLoading ? "scale(0)" : "scale(1)",
    config: { tension: 200, friction: 10 },
  });

  useEffect(() => {
   setTimeout(() => {
    setIsLoading(false)
   }, 1500);
   setTimeout(() => {
    navigate('/orderHistory')
   }, 7000);
  }, [])
  

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
      }}
    >
       <animated.div style={resultAnimation}>
          <Result
            status="error"
            title="Payment verification failed"
            style={{ fontFamily: Theme.customFont.custFontNormal }}
            extra={[
              <Button
                type="primary"
                key="console"
                onClick={() => navigate("/orderHistory")}
                style={{
                  fontFamily: Theme.customFont.custFontNormal,
                  backgroundColor: "red",
                }}
              >
                Go to Orders
              </Button>,
            ]}
          />
        </animated.div>
    </div>
  );
};

export default CancelPage;