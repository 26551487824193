import React from "react";
import { Col, Image, List, Row, Typography } from "antd";
import Theme from "../utils/Theme";
export const ReturnPolicy = () => {
  const { Title, Text, Paragraph, Link } = Typography;
  return (
    <Row justify="center" style={{ padding: "20px" }}>
      <Col xs={24} md={18} lg={16}>
        <Typography>
          <Title style={{fontFamily: Theme.customFont.custFontNormal}} level={2}>RETURNS & REFUNDS POLICY </Title>
          <Title style={{fontFamily: Theme.customFont.custFontNormal}} level={5}>Returns</Title>
          <Paragraph style={{fontFamily: Theme.customFont.custFontNormal}}>
            We want you to love your purchase. If you are not happy, please get
            in touch at{" "}
            <Link href="mailto:info@desibanter.co.uk">
              info@desibanter.co.uk
            </Link>{" "}
            to discuss the issue.
          </Paragraph>

          <Paragraph style={{fontFamily: Theme.customFont.custFontNormal}}>
            Our policy lasts 30 days. If 30 days have gone by since your
            purchase, unfortunately we can’t offer you a refund or an exchange.
          </Paragraph>

          <Paragraph style={{fontFamily: Theme.customFont.custFontNormal}}>
            To be eligible for a return, your item must be unused and in the
            same condition that you received it. It must also be in the original
            packaging.
          </Paragraph>

          <Paragraph style={{fontFamily: Theme.customFont.custFontNormal}}>
            To complete your return, we require a receipt or proof of purchase
            (such as packing slip or order confirmation email).
          </Paragraph>

          <Paragraph style={{fontFamily: Theme.customFont.custFontNormal}}>
            There are certain situations where only partial refunds are granted
            (if applicable)
          </Paragraph>
          <Paragraph style={{fontFamily: Theme.customFont.custFontNormal}}>
            - Any item not in its original condition, is damaged or missing
            parts for reasons not due to our error.
          </Paragraph>
          <Paragraph style={{fontFamily: Theme.customFont.custFontNormal}}>
            {" "}
            - Any item that is returned more than 30 days after delivery.
          </Paragraph>
          <Title style={{fontFamily: Theme.customFont.custFontNormal}} level={4}>Refunds (if applicable) </Title>
          <Paragraph style={{fontFamily: Theme.customFont.custFontNormal}}>
            Once your return is received and inspected, we will send you an
            email to notify you that we have received your returned item.
            <br /> If you are approved, then your refund will be processed, and
            a credit will automatically be applied to your credit card or
            original method of payment, within a certain amount of days.
          </Paragraph>

          <Title style={{fontFamily: Theme.customFont.custFontNormal}} level={4}>Late or missing refunds (if applicable)</Title>

          <Paragraph style={{fontFamily: Theme.customFont.custFontNormal}}>
            If you haven’t received a refund yet, first check your bank account
            again.
            <br />
            Then contact your credit card company, it may take some time before
            your refund is officially posted. Next contact your bank. There is
            often some processing time before a refund is posted.
            <br />
            If you’ve done all of this and you still have not received your
            refund yet, please contact us at{" "}
            <Link href="mailto:info@desibanter.co.uk">
              {" "}
              info@desibanter.co.uk
            </Link>{" "}
          </Paragraph>

          <Title style={{fontFamily: Theme.customFont.custFontNormal}} level={4}>Sale items (if applicable)</Title>

          <Paragraph style={{fontFamily: Theme.customFont.custFontNormal}}>
            Only regular priced items may be refunded, unfortunately sale items
            cannot be refunded.
          </Paragraph>

          <Title style={{fontFamily: Theme.customFont.custFontNormal}} level={4}>Exchanges (if applicable)</Title>

          <Paragraph style={{fontFamily: Theme.customFont.custFontNormal}}>
            We only replace items if they are defective or damaged. If you need
            to exchange it for the same item, send us an email at
            info@desibanter.co.uk and send your item to:
          </Paragraph>
          <Paragraph style={{fontFamily: Theme.customFont.custFontNormal}} strong>Desi Banter Ltd</Paragraph>

          <Title style={{fontFamily: Theme.customFont.custFontNormal}} level={4} italic>
            {/* 153 Horton Road, Stanwell Moor, Surrey TW19 6AF */}
          </Title>

          <Title style={{fontFamily: Theme.customFont.custFontNormal}} level={4}>Shipping </Title>

          <Paragraph style={{fontFamily: Theme.customFont.custFontNormal}}>
            To return your product, you should mail your product to:{" "}
            <b>
              {/* Desi Banter Ltd153 Horton Road, Stanwell Moor, Surrey TW19 6AF
              United Kingdom. */}
            </b>
          </Paragraph>

          <Paragraph style={{fontFamily: Theme.customFont.custFontNormal}}>
            You will be responsible for paying for your own shipping costs for
            returning your item. Shipping costs are non-refundable. If you
            receive a refund, the cost of shipping will be deducted from your
            refund.
          </Paragraph>

          <Paragraph style={{fontFamily: Theme.customFont.custFontNormal}}>
            Depending on where you live, the time it may take for your exchanged
            product to reach you, may vary.
          </Paragraph>

          <Paragraph style={{fontFamily: Theme.customFont.custFontNormal}}>
            If you are shipping an item back to us, you should consider using a
            trackable shipping service or obtaining a Proof Of Posting from the
            Post Office. We can't process a refund if the item doesn't reach us.
          </Paragraph>

          <Paragraph style={{fontFamily: Theme.customFont.custFontNormal}}>Many Thanks</Paragraph>
          <Paragraph style={{fontFamily: Theme.customFont.custFontNormal}}></Paragraph>
          <Paragraph style={{fontFamily: Theme.customFont.custFontNormal}} strong>Desi Banter</Paragraph>

          <Row>
            <Col>
              <Image
                src={require("../assets/images/logo.jpeg")}
                alt="Desi Banter Logo"
                style={{ width: 80, height: 80 }}
              />
            </Col>
          </Row>
        </Typography>
      </Col>
    </Row>
  );
};
