import React from 'react';
import { Row, Col, Badge } from 'antd';
import { ShoppingOutlined } from "@ant-design/icons";
import '../App.css';
const Header = () => {
  return (
    <div>
    <Row gutter={16}>
      <Col span={8}>
        <img src={require('../assets/images/headerpic1.png')} alt="Image 1" style={imageStyle}/>
      </Col>
      <Col span={8}>
        <img src={require('../assets/images/logos.png')} alt="Image 2"   className= 'responsive-image'/>
      </Col>
      <Col span={8}>
        <img src={require('../assets/images/headerpics2.png')} alt="Image 3" style={imageStyle} />
      </Col>
    </Row>
    </div>
  );
};

const headerStyle = {
    background: '#fff',
    height: '15rem'
    //padding: '20px',
};

const imageStyle = {
    width: '100%',
    height: '25rem',
    //border: '5px solid black',
    objectFit: 'contain',
   // maxWidth: '200px',
    //margin: '2px 0',
};
const imageStyle2 = {
    width: '100%',
    height: '25rem',
    //border: '5px solid black',
    objectFit: 'cover',
   // maxWidth: '200px',
    //margin: '2px 0',
};

export default Header;