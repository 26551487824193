import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Card,
  Image,
  Typography,
  Badge,
  Rate,
  Button,
  message,
  Pagination,
  InputNumber,
  Spin,
  Empty,
} from "antd";
import "../App.css";
import { useNavigate } from "react-router";
import { useLocation } from "react-router-dom";
import { MessageModel } from "./MessageModel";
import { useDispatch } from "react-redux";
import { increment } from "../redux/counter/CounterSlice";
import { addToCart } from "../redux/counter/CartSlice";
import Theme from "../utils/Theme";
import { API } from "../api";
export const Cards = ({ productsData, selectedCategory, selectedSearch }) => {
  const { Meta } = Card;
  const navigate = useNavigate();
  const location = useLocation();
  const discount = {
    id: 4,
    dis: true,
  };
  const dispatch = useDispatch();
  const [countMap, setCountMap] = useState({});
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [messages, setMessages] = useState({});
  const [selectedCardId, setSelectedCardId] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setisLoading] = useState(false);
  const pageSize = 12; // Number of cards per page
  const handleCountChange = (id, value) => {
    const currentCount = countMap[id] || 1;

    if (value > currentCount) {
      // Call increment function
      incrementFunction(id, value);
    } else if (value < currentCount) {
      // Call decrement function
      decrementFunction(id, value);
    }

    // Update the countMap or perform any other necessary actions
    // based on the new value
    setCountMap({ ...countMap, [id]: value });
  };

  const incrementFunction = (id, value) => {
    // Your increment logic here
  };

  const decrementFunction = (id, value) => {
    // Your decrement logic here
  };
  useEffect(() => {
    // Check if there is state information related to the current page
    const currentPageFromState = location.state?.currentPage;

    if (currentPageFromState !== undefined) {
      setCurrentPage(currentPageFromState);
      setisLoading(true)
      setTimeout(() => {
        setisLoading(false)
      }, 2000);
    }
    
    
  }, [location.state]);

  useEffect(() => {
   if(selectedCategory && currentPage !== 1){
      setCurrentPage(1)
    } 
    setisLoading(true)
    setTimeout(() => {
      setisLoading(false)
    }, 1500);
  }, [selectedSearch, selectedCategory])
  
  const handleCardClick2 = (cardId) => {
    // Handle card click logic
    setSelectedCardId(cardId);
    setIsModalVisible(true);
  };

  const handleAddMessage = () => {
    // Handle adding a message for the given card

    setMessages((prevMessages) => ({
      ...prevMessages,
      [selectedCardId]: inputValue,
    }));

    setInputValue("");
    // Close the modal
    setIsModalVisible(false);
  };

  const handlePageChange = (page) => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    setCurrentPage(page);
    navigate(".", { state: { currentPage: page } });
  };

  const onClickItem = async (e) => {
    let data = null;
    try {
      const response = await API.get(`item?item_id=${e._id}`);
  data = await response.data
    } catch (error) {
      console.error(error, 'error');
      // Instead of returning the entire error object, you can handle the error here
    }
    data && navigate(`/productDetail/${e._id}`, { state: { product: data } })
  }
  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = currentPage * pageSize;
  const centerStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '60vh', // Set the Spin container to cover the full viewport height
  };
  return (
    <>
    {isLoading ? (
      <div style={centerStyle}>
        <Spin tip="Loading" size="large" >
        <div className="content" />
        </Spin>
      </div>
    ) : (
      <>
      {productsData?.length > 0 ?   <Row gutter={[16,16]} style={{marginLeft: 30, marginRight:30}}>
        
       

{productsData?.length === 1
      ?  (
      
        productsData?.map((e) => (
          <Col
              key={e.id}
              xs={24} // Set full width on extra small screens
      sm={12} // Set 50% width on small screens
      md={8}  // Set 33.33% width on medium screens
      lg={6}  // Set 25% width on large screens
      style={{ padding: 10 ,}}
            >
              {discount.id == e.id ? (
                <Card
                onClick={()=> onClickItem(e)}
                className="cardstyle image" // Add your existing styles here
                cover={<Image alt="example"  src={e.thumbnail} preview={false}  style={{width: '100%',
                height: 320,
                objectFit: 'cover'}}/>}
                //hoverable // Add the hoverable prop
                
              >
               <Meta
                  style={{ display: "contents" }}
                  
                  description={
                    <div style={{display:'flex', flexDirection: 'column',}} className='image' >
                      <Typography.Text strong style={{fontFamily: Theme.customFont.custFontNormal}}>{e.title}</Typography.Text>
                      <Typography.Paragraph >
                        Price: £{e.price}{" "}
                        <Typography.Text delete type="danger">
                          was 234
                        </Typography.Text>
                      </Typography.Paragraph>
                      {/* <Typography.Text
                      className="imagetext"
                        style={{
                          backgroundColor: "#a0e193",
                          borderRadius: "20px",
                          fontSize: 11,
                          fontWeight: "bold",
                          color: "#000",
                          paddingLeft: 4,
                          paddingRight: 4,
                          paddingTop: 2,
                          paddingBottom: 2,
                          alignItems: "center",
                          top:0,
                          fontFamily: Theme.customFont.custFontNormal
                        }}
                      >
                        FREE UK delivery
                      </Typography.Text> */}
                    </div>
                  }
                  price={e.price}
                />
              </Card>
              ) : (
                <Card
                onClick={()=> onClickItem(e)}
                  className="cardstyle image" // Add your existing styles here
                  cover={<Image alt="example" src={e.thumbnail} preview={false} style={{width: '100%',
                    height: 320,
                    objectFit: 'cover'}} />}
                  //hoverable // Add the hoverable prop
                  
                >
                 <Meta
                    style={{ display: "contents" }}
                    
                    description={
                      <div style={{display:'flex', flexDirection: 'column',}} className='image' >
                        <Typography.Text ellipsis={{ rows: 1, expandable: true }} style={{width: '100%', fontFamily: Theme.customFont.custFontNormal, marginTop:5}} strong>{e.title}</Typography.Text>
                        <Typography.Paragraph style={{ fontFamily: Theme.customFont.custFontNormal, fontWeight: 'bold', marginTop: 5}}>
                          Price: £{e.price.toFixed(2)}
                        </Typography.Paragraph>
                        {/* <Typography.Text
                        className="imagetext"
                          style={{
                            backgroundColor: "#a0e193",
                            borderRadius: "20px",
                            fontSize: 11,
                            fontWeight: "bold",
                            color: "#000",
                            paddingLeft: 4,
                            paddingRight: 4,
                            paddingTop: 2,
                            paddingBottom: 2,
                            fontFamily: Theme.customFont.custFontNormal,
                            alignItems: "center",
                            top:0,
                          }}
                        >
                          FREE UK delivery
                        </Typography.Text> */}
                      </div>
                    }
                    price={e.price}
                  />
                </Card>
              )}
            </Col>
        ))
      )
      : (
        productsData.slice(startIndex, Math.min(endIndex, productsData.length)).map((e) => (
          <Col
              key={e.id}
              xs={24} // Set full width on extra small screens
      sm={12} // Set 50% width on small screens
      md={8}  // Set 33.33% width on medium screens
      lg={6}  // Set 25% width on large screens
      style={{ padding: 10 ,}}
            >
              {discount.id == e.id ? (
                <Card
                onClick={()=> onClickItem(e)}
                className="cardstyle image" // Add your existing styles here
                cover={<Image alt="example"  src={e.thumbnail} preview={false}  style={{width: '100%',
                height: 320,
                objectFit: 'cover'}}/>}
                //hoverable // Add the hoverable prop
                
              >
               <Meta
                  style={{ display: "contents" }}
                  
                  description={
                    <div style={{display:'flex', flexDirection: 'column',}} className='image' >
                      <Typography.Text strong style={{fontFamily: Theme.customFont.custFontNormal}}>{e.title}</Typography.Text>
                      <Typography.Paragraph >
                        Price: £{e.price}{" "}
                        <Typography.Text delete type="danger">
                          was 234
                        </Typography.Text>
                      </Typography.Paragraph>
                      {/* <Typography.Text
                      className="imagetext"
                        style={{
                          backgroundColor: "#a0e193",
                          borderRadius: "20px",
                          fontSize: 11,
                          fontWeight: "bold",
                          color: "#000",
                          paddingLeft: 4,
                          paddingRight: 4,
                          paddingTop: 2,
                          paddingBottom: 2,
                          alignItems: "center",
                          top:0,
                          fontFamily: Theme.customFont.custFontNormal
                        }}
                      >
                        FREE UK delivery
                      </Typography.Text> */}
                    </div>
                  }
                  price={e.price}
                />
              </Card>
              ) : (
                <Card
                onClick={()=> onClickItem(e)}
                  className="cardstyle image" // Add your existing styles here
                  cover={<Image alt="example" src={e.thumbnail} preview={false} style={{width: '100%',
                    height: 320,
                    objectFit: 'cover'}} />}
                  //hoverable // Add the hoverable prop
                  
                >
                 <Meta
                    style={{ display: "contents" }}
                    
                    description={
                      <div style={{display:'flex', flexDirection: 'column',}} className='image' >
                        <Typography.Text ellipsis={{ rows: 1, expandable: true }} style={{width: '100%', fontFamily: Theme.customFont.custFontNormal, marginTop:5}} strong>{e.title}</Typography.Text>
                        <Typography.Paragraph style={{ fontFamily: Theme.customFont.custFontNormal, fontWeight: 'bold', marginTop: 5}}>
                          Price: £{e.price.toFixed(2)}
                        </Typography.Paragraph>
                        {/* <Typography.Text
                        className="imagetext"
                          style={{
                            backgroundColor: "#a0e193",
                            borderRadius: "20px",
                            fontSize: 11,
                            fontWeight: "bold",
                            color: "#000",
                            paddingLeft: 4,
                            paddingRight: 4,
                            paddingTop: 2,
                            paddingBottom: 2,
                            fontFamily: Theme.customFont.custFontNormal,
                            alignItems: "center",
                            top:0,
                          }}
                        >
                          FREE UK delivery
                        </Typography.Text> */}
                      </div>
                    }
                    price={e.price}
                  />
                </Card>
              )}
            </Col>
        ))
      )
    }
  
      </Row> : <Empty/> }
      
      {productsData.length > pageSize && (
      <Pagination
        current={currentPage}
        total={productsData.length}
        pageSize={pageSize}
        onChange={handlePageChange}
        style={{
          marginTop: "25px",
          textAlign: "center",
          marginBottom: "25px",
          fontSize: "1.3em",
        }}
      />
    )}
    </>
    )}
    </>
  );
};

const AddToCartButton = ({ item, onButtonClick, cardmessage }) => {
  return (
    <Button
      className="btn-add"
      onClick={() => {
        const id = item.id.toString();
        if (cardmessage.hasOwnProperty(id)) {
          item = {
            ...item,
            message: cardmessage[id],
          };
        }
        message.success(`${item.title} has been added to cart`);
        onButtonClick(increment());
        onButtonClick(addToCart(item));
      }}
      type="link"
      style={{
        backgroundColor: "rgb(241, 60, 60)",
        color: "white",
        transition: "transform 0.3s ease-in-out",
      }}
      onMouseOver={(e) => {
        e.currentTarget.style.transform = "scale(1.1)"; // Increase the size on hover
      }}
      onMouseOut={(e) => {
        e.currentTarget.style.transform = "scale(1)"; // Reset the size on mouse out
      }}
    >
      Add to Cart
    </Button>
  );
};

//style={{width: 150, height: 150, display: 'block', margin: 'auto', paddingTop: 20}}
