import React from 'react';
import "mdb-react-ui-kit/dist/css/mdb.min.css";
import { MDBContainer, MDBRow, MDBCol, MDBCard, MDBCardBody, MDBCardHeader, MDBTypography, MDBBtn } from 'mdb-react-ui-kit';
import { Typography } from 'antd';
import Theme from '../utils/Theme';

const OrderDetails = () => {
  // Dummy data for shipping details
  const shippingDetails = {
    name: 'John Doe',
    email: 'john@example.com',
    phone_number: '1234567890',
    address: '123 Main St',
    city: 'Cityville',
    town: 'Townsville',
    postalcode: '12345',
    country: 'Countryland',
  };

  // Dummy data for cart item
  const cartItem = {
    _id: '1',
    title: 'Product Name',
    description: 'Product Description',
    thumbnail: 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR1ndZugfkNctF3VPlVmwiW_FcIOcLdAwSVoVuvEQaD2w&s',
    quantity: 5,
    price: 10,
  };

  // Function to handle adding a review
  const handleAddReview = () => {
    // Add your logic here
    console.log('Adding a review');
  };

  return (
    
      <MDBContainer className="py-2 h-100">
        <Typography.Title style={{alignSelf: 'center'}} level={2}>Order Id: DBM200221</Typography.Title>
        <MDBRow className=" my-4">
          <MDBCol md="8">
          <MDBCard className="mb-4">
              <MDBCardBody>
                {/* Dummy shipping details */}
                <div style={{ marginBottom: 20 }}>
                  <p style={{ fontFamily: Theme.customFont.custFontNormal }}>
                    <strong>Name:</strong> {shippingDetails.name}
                  </p>
                  <p style={{ fontFamily: Theme.customFont.custFontNormal }}>
                    <strong>Email:</strong> {shippingDetails.email}
                  </p>
                  <p style={{ fontFamily: Theme.customFont.custFontNormal }}>
                    <strong>Phone No:</strong> {shippingDetails.phone_number}
                  </p>
                  <p style={{ fontFamily: Theme.customFont.custFontNormal }}>
                    <strong>Address:</strong> {shippingDetails.address}
                  </p>
                  <p style={{ fontFamily: Theme.customFont.custFontNormal }}>
                    <strong>City:</strong> {shippingDetails.city}
                  </p>
                  <p style={{ fontFamily: Theme.customFont.custFontNormal }}>
                    <strong>Town:</strong> {shippingDetails.town}
                  </p>
                  <p style={{ fontFamily: Theme.customFont.custFontNormal }}>
                    <strong>Postal Code:</strong> {shippingDetails.postalcode}
                  </p>
                  <p style={{ fontFamily: Theme.customFont.custFontNormal }}>
                    <strong>Country:</strong> {shippingDetails.country}
                  </p>
                </div>
                <p style={{ fontFamily: Theme.customFont.custFontNormal }}>
                  <strong>Total:</strong> £{(cartItem.quantity * cartItem.price).toFixed(2)}
                </p>
               
              </MDBCardBody>
            </MDBCard>
            <MDBCard className="mb-4">
              
              <MDBCardBody>
                <MDBRow>
                  <MDBCol lg="3" md="12" className="mb-4 mb-lg-0">
                    <img
                      src={cartItem.thumbnail}
                      alt="Product Thumbnail"
                      style={{ height: 120, width: '100%', objectFit: 'cover' }}
                    />
                  </MDBCol>
                  <MDBCol lg="5" md="6" className="mb-4 mb-lg-0">
                    <p style={{ fontFamily: Theme.customFont.custFontNormal }}>
                      <strong>{cartItem.title}</strong>
                    </p>
                    <Typography.Paragraph
                      ellipsis={{ rows: 3, expandable: true, symbol: 'more' }}
                      style={{ fontFamily: Theme.customFont.custFontNormal }}
                    >
                      {cartItem.description}
                    </Typography.Paragraph>
                  </MDBCol>
                  <MDBCol lg="4" md="6" className="mb-4 mb-lg-0" style={{ display: "flex", flexDirection: "column" }}>
                    <MDBBtn
                      onClick={handleAddReview}
                      className="px-3 ms-2"
                      style={{
                        backgroundColor: 'rgb(243, 70, 70)',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        fontWeight: 'bold',
                        textTransform: 'none',
                        fontSize: 15
                        
                      }}
                    >
                    Wright Review
                    </MDBBtn>
                    <p
                              className="text-center mt-3"
                              style={{
                                fontFamily: Theme.customFont.custFontNormal,
                              }}
                            >
                              <strong>Price: £3.45</strong>
                            </p>
                  </MDBCol>
                </MDBRow>
                <hr className="my-4" />
              </MDBCardBody>
            </MDBCard>

            
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    
  );
};

export default OrderDetails;