import { Col, Row, Typography } from "antd";
import React from "react";
import { ContactForm } from "../components/ContactForm";
import Theme from "../utils/Theme";

export const ContactUs = () => {
  return (
    <>
      {/* <Row
      justify={'center'} align='middle'
        style={{ backgroundColor: "red", textAlign: "center", height: "30px" }}
      >
        <Col
          style={{
            color: "white",
            fontFamily: "Poppins, sans-serif",
            fontWeight: 600,
            fontSize: 15,
          }}
          span={24}
        >
          FREE UK SHIPPING OVER £23
        </Col>
      </Row> */}
      <Row  justify="center"  style={{ marginTop: 50,}}>
        <Col
          //className="gutter-row"
          xs={{ span: 12 }}
          sm={{ span: 13 }}
          md={{ span: 8 }}
          lg={{ span: 9 }}
          
        >
          <div style={{ width:'100%', alignSelf:'center'}}>
          <h2 style={{ fontFamily: Theme.customFont.custFontNormal, fontSize: 28 }}>
            CONTACT US
          </h2>
          <Typography.Paragraph  variant="body1" gutterBottom className="contactfont" style={{fontFamily: Theme.customFont.custFontNormal}}>
            If you can’t find an answer to your question in our Frequently Asked
            Questions section, you can send us a message below and we’ll get
            back to you as soon as possible!
          </Typography.Paragraph>
          
          </div>
        </Col>
        
          
          
      </Row>
      <Row justify='center'  style={{ marginTop: 50, }}>
        <Col
          className="gutter-row"
          xs={{ span: 12 }}
          sm={{ span: 18 }}
          md={{ span: 16 }}
          lg={{ span: 18 }}
        >
          <ContactForm />
        </Col>
      </Row>
    </>
    
  );
};
