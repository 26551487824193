import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import { useTheme } from '@mui/material/styles';
import Theme from "../utils/Theme";

const currencies = [
  // {
  //   value: "feature",
  //   label: "Featured Items",
  // },
  {
    value: "lowhigh",
    label: "Low to High",
  },
  {
    value: "highlow",
    label: "High to Low",
  },
  {
    value: "resent",
    label: "Most Recent",
  },
  // {
  //   value: "toprated",
  //   label: "Customer's Top Rated",
  // },
  // {
  //   value: "bestseller",
  //   label: "Best Sellers",
  // },
  // {
  //   value: "New Arrival",
  //   label: "New Arrival",
  // },
];

export const SelectDropDown = ({setSortedItems}) => {
  const [selectedOption, setSelectedOption] = useState("resent");
  const theme = useTheme()
  const handleChange = (event) => {
    setSelectedOption(event.target.value);
  };
  useEffect(() => {
    setSortedItems(selectedOption);
  }, [selectedOption])
  
  return (
    <Box
      component="form"
      sx={{
        "& .MuiTextField-root": {
          m: 1,
          //width: '100%',
          [theme.breakpoints.up('md')]: {
            width: '25ch', // Width on medium and larger screens
          },
          "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: "rgb(243, 98, 98)",
            transition: 'box-shadow 0.3s',
            boxShadow: '0 2px 4px rgb(243, 98, 98)',
          },
          "&:focus .MuiOutlinedInput-notchedOutline" : {
            borderColor: "rgb(241, 191, 191)",
          },
          
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "rgb(241, 90, 90)",
            borderWidth: 2
          },
        },
        "& .menu-item:hover": {
          backgroundColor: "red",
          color: "white",
        },
      }}
      noValidate
      autoComplete="off"
    >
      <TextField
        id="outlined-select-currency"
        select
        label="Sort by"
        defaultValue="Most Recent"
        InputProps={{
          style:{ fontFamily: Theme.customFont.custFontNormal}
        }}
        variant="outlined"
        value={selectedOption}
        onChange={handleChange}
        InputLabelProps={{
            style: {
              fontWeight: 600,
              color: '#000',
              fontFamily: Theme.customFont.custFontNormal
            },
          }}
        //helperText="Please select your currency"
      >
        {currencies.map((option) => (
          <MenuItem
            key={option.value}
            value={option.value}
            style={{fontFamily: Theme.customFont.custFontNormal}}
            className={selectedOption === option.value ? "selected-option" : ""}
            sx={{
                '&.selected-option': {
                    backgroundColor: 'rgb(241, 151, 151)',
                    color: 'white',
                    fontFamily: Theme.customFont.custFontNormal
                  },
                  '&:hover': {
                    backgroundColor: 'rgb(241, 191, 191)',
                    color: 'white',
                  },
            }}
          >
            {option.label}
          </MenuItem>
        ))}
      </TextField>
    </Box>
  );
};
