import React, { useState } from "react";
import { Avatar, Dropdown, Menu } from "antd";
import { UserOutlined, WomanOutlined, ManOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { logOut } from "../redux/counter/AuthSlice";
import { useNavigate, useNavigation } from "react-router";
import Theme from "../utils/Theme";

const UserProfileDropdown = () => {
  const [visible, setVisible] = useState(false);
  const { profileData } = useSelector((state) => state.userProfile);
  
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleMenuClick = ({ key }) => {
    // Handle menu item click
    if (key === "logout") {
      // Perform logout logic
      dispatch(logOut());
      navigate("/");
    } else if (key === "myorder") {
      navigate("/orderHistory");
    } else if(key === 'profile'){
      navigate('/profile')
    } else if(key === 'mywishlist'){
      navigate('/myfavourites')
    }
     else {
      // Handle other menu items as needed
    }
    setVisible(false); // Close the dropdown after clicking an item
  };

  const menu = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item key="profile" style={{fontFamily: Theme.customFont.custFontNormal}}>Profile</Menu.Item>
      <Menu.Item key="myorder" style={{fontFamily: Theme.customFont.custFontNormal}}>My Orders</Menu.Item>
      <Menu.Item key="mywishlist" style={{fontFamily: Theme.customFont.custFontNormal}}>My Favourites</Menu.Item>
      <Menu.Divider />
      <Menu.Item key="logout" style={{fontFamily: Theme.customFont.custFontNormal}}>Logout</Menu.Item>
    </Menu>
  );

  
    const renderAvatar = () => {
      // Check if profile image exists
      if (profileData?.data?.profile_image) {
        return <Avatar src={profileData?.data?.profile_image} alt="Profile" />;
      } else {
        // Check gender and display corresponding icon
        if (profileData?.data?.gender === "male" && !profileData?.data?.profile_image) {
          return <Avatar  src={require('../assets/images/manicon.jpg')} />;
        } else if (profileData?.data?.gender === "female" && !profileData?.data?.profile_image) {
          return <Avatar  src={require('../assets/images/womenicon.jpg')} />;
        } else {
          // If no profile image and gender, default to generic user icon
          return <Avatar style={{backgroundColor:'red'}}  icon={<UserOutlined />} />;
        }
      }
    };
  

  

  return (
    <Dropdown
      overlay={menu}
      trigger={["click"]}
      visible={visible}
      onVisibleChange={(flag) => setVisible(flag)}
    >
     {renderAvatar()}
    </Dropdown>
  );
};

export default UserProfileDropdown;
