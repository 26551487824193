import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { API } from "../../api/index";

const initialState = {
  userData: null,
  signupData: null,
  isLoading: false,
  isSucess: false,
  isError: false,
  loginError: null,
  signupError: null,
};

export const login = createAsyncThunk("login", async (params, thunkApi) => {
  try {
    const response = await API.post("customer/login", params);
    return response.data;
  } catch (error) {
    // Instead of returning the entire error object, throw a new Error
    // throw new Error(error.message);
    return thunkApi.rejectWithValue(error)
  }
});

export const signup = createAsyncThunk("signup", async (params, thunkApi) => {
  try {
    const response = await API.post("customer/signup", params, {headers: {
      "Content-Type": 'application/json'
    }});
    return response.data;
  } catch (error) {
   return thunkApi.rejectWithValue(error)
  }
});

const AuthSlice = createSlice({
  name: "authSlice",
  initialState,
  reducers: {
    logOut: (state) => {
      return initialState;
    },
    clearLoginError: (state) => {
      state.loginError = null;
    },
    clearSignupError: (state) => {
      state.signupError = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(login.pending, (state) => {
      state.isLoading = true;
      state.loginError= null;
      state.isError = false;
      state.isSucess= false;
    });
    builder.addCase(login.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isSucess = true;
      state.isError = false;
      state.userData = action.payload;
      state.loginError= null;
    });
    builder.addCase(login.rejected, (state, action) => {
      state.loginError = action.payload.response.data.data.message
      state.isLoading = false;
      state.isError = true;
      state.isSucess =false;
    });
    builder.addCase(AuthSlice.actions.logOut, (state) => {
      return initialState;
    });
    builder.addCase(signup.pending, (state) => {
      state.isLoading = true;
      state.isError = false;
      state.isSucess= false;
      state.signupError = null
    });
    builder.addCase(signup.fulfilled, (state, action) => {
      state.isSucess = true;
      state.isError = false;
      state.isLoading = false;
      state.signupData = action.payload;
      state.signupError = null;
    });
    builder.addCase(signup.rejected, (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.isSucess =false;
      state.signupError = action?.payload?.response.data.data.message
    });
  },
});

export const { logOut, clearLoginError, clearSignupError } = AuthSlice.actions;

export default AuthSlice.reducer;
