import React from 'react'
import {
    MDBCard,
    MDBCardBody,
    MDBCardFooter,
    MDBCardHeader,
    MDBCardImage,
    MDBCol,
    MDBContainer,
    MDBProgress,
    MDBProgressBar,
    MDBRow,
    MDBTypography,
  } from "mdb-react-ui-kit";

import '../App.css';
import { useSelector } from 'react-redux';
import { Empty, Image, Typography } from 'antd';

export const MyOrders = () => {

    const {cartData} = useSelector(state=> state.cartItems)
  return (
    
    <div
      className="h-100 gradient-custom"
      style={{ backgroundColor: "#eee" }}
    >
        {cartData && cartData.length > 0 ? 
      <MDBContainer className="py-5 h-100">
        <MDBRow className="justify-content-center align-items-center h-100">
          <MDBCol lg="10" xl="8">
            <MDBCard style={{ borderRadius: "10px" }}>
              <MDBCardHeader className="px-4 py-5">
                <MDBTypography tag="h5" className="text-muted mb-0">
                  Thanks for your Order,{" "}
                  <span style={{ color: "#a8729a" }}>Muhammad</span>!
                </MDBTypography>
              </MDBCardHeader>
              <MDBCardBody className="p-4">
                <div className="d-flex justify-content-between align-items-center mb-4">
                  <p
                    className="lead fw-normal mb-0"
                    style={{ color: "#a8729a" }}
                  >
                    Your Orders
                  </p>
                  
                </div>
   {cartData && cartData.length > 0 && cartData.map((ele, index)=> {
return  <MDBCard key={ele.id} className="shadow-0 border mb-4">
<MDBCardBody>
  <MDBRow>
    <MDBCol md="2">
      <Image
        src={ele.image}
        fluid
        alt="Phone"
        style={{height: 100, width: 100}}
      />
    </MDBCol>
    <MDBCol
      md="2"
      className="text-center d-flex justify-content-center align-items-center"
    >
      <p className="text-muted mb-0">{ele.title}</p>
    </MDBCol>
    <MDBCol
      md="2"
      className=" d-flex justify-content-center align-items-center"
    >
        
        <Typography.Paragraph  ellipsis={{
                rows: 2,
                expandable: true,
                symbol: 'more',
                }}>
                  {ele.description}
                </Typography.Paragraph>
        
        
      {/* <p className="text-muted mb-0 small">{ele.description}</p> */}
    </MDBCol>
    {/* <MDBCol
      md="2"
      className="text-center d-flex justify-content-center align-items-center"
    >
      <p className="text-muted mb-0 small">
        Capacity: 64GB
      </p>
    </MDBCol> */}
    <MDBCol
      md="2"
      className="text-center d-flex justify-content-center align-items-center"
    >
      <p className="text-muted mb-0 small">Qty: {ele.quantity}</p>
    </MDBCol>
    <MDBCol
      md="2"
      className="text-center d-flex justify-content-center align-items-center"
    >
      <p className="text-muted mb-0 small">Price: £ {ele.price}</p>
    </MDBCol>
    <MDBCol
      md="2"
      className="text-center d-flex justify-content-center align-items-center"
    >
      <p className="text-muted mb-0 small">DC: £ 3.99</p>
    </MDBCol>
  </MDBRow>
  <hr
    className="mb-4"
    style={{ backgroundColor: "#e0e0e0", opacity: 1 }}
  />
  
</MDBCardBody>
</MDBCard>
   })}
                


                <div className="d-flex justify-content-between pt-2">
                  <p className="fw-bold mb-0">Order Details</p>
                  
                </div>

                <div className="d-flex justify-content-between pt-2">
                  <p className="text-muted mb-0">Order Number : 788152</p>
                  
                </div>

                <div className="d-flex justify-content-between">
                  <p className="text-muted mb-0">
                    Order Date : 29 Jan,2024
                  </p>
                  
                </div>

                {/* <div className="d-flex justify-content-between mb-5">
                  <p className="text-muted mb-0">
                    
                  </p>
                  <p className="text-muted mb-0">
                    <span className="fw-bold me-4">Delivery Charges</span>{" "}
                    Free
                  </p>
                </div> */}
              </MDBCardBody>
              <MDBCardFooter
                className="border-0 px-4 py-5"
                style={{
                  backgroundColor: "rgb(242, 90, 90)",
                  borderBottomLeftRadius: "10px",
                  borderBottomRightRadius: "10px",
                }}
              >
                <MDBTypography
                  tag="h3"
                  className="d-flex align-items-center justify-content-end text-white text-uppercase mb-0"
                >
                  Total paid: <span className="h2 mb-0 ms-2">£112.99</span>
                </MDBTypography>
              </MDBCardFooter>
            </MDBCard>
          </MDBCol>
        </MDBRow>
      </MDBContainer>  : <div style={{height: '80vh', alignItems:'center', justifyContent: 'center', display:'flex'}}><Empty/></div>}
    </div>
  
  )
}
