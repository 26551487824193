import React from "react";
import { Col, Image, List, Row, Typography } from "antd";
import Theme from "../utils/Theme";
export const PrivacyCookie = () => {
  const { Title, Text, Paragraph, Link } = Typography;
  return (
    <Row justify="center" style={{ padding: "20px" }}>
      <Col xs={24} md={18} lg={16}>
        <Typography>
          <Title style={{fontFamily: Theme.customFont.custFontNormal}} level={2}>PRIVACY AND COOKIES</Title>

          <Paragraph style={{fontFamily: Theme.customFont.custFontNormal}}>
            This Privacy Policy describes how your personal information is
            collected, used, and shared when you visit or make a purchase from{" "}
            <Link href=" www.desibanter.co.uk" target="_blank">
              {" "}
              www.desibanter.co.uk
            </Link>{" "}
            (the “Site”).
          </Paragraph>
          <Title style={{fontFamily: Theme.customFont.custFontNormal}} level={4}>Personal information we collect</Title>
          <Paragraph style={{fontFamily: Theme.customFont.custFontNormal}}>
            When you visit the Site, we automatically collect certain
            information about your device, including information about your web
            browser, IP address, time zone, and some of the cookies that are
            installed on your device. Additionally, as you browse the Site, we
            collect information about the individual web pages or products that
            you view, what websites or search terms referred you to the Site,
            and information about how you interact with the Site. We refer to
            this automatically-collected information as “Device Information”.
          </Paragraph>

          <Paragraph style={{fontFamily: Theme.customFont.custFontNormal}}>
            We collect Device Information using the following technologies:
            <br />- “Cookies” are data files that are placed on your device or
            computer and often include an anonymous unique identifier. For more
            information about cookies, and how to disable cookies, visit{" "}
            <Link href=" http://www.allaboutcookies.org" target="_blank">
              {" "}
              http://www.allaboutcookies.org.
            </Link>
            <br />
            - “Log files” track actions occurring on the Site, and collect data
            including your IP address, browser type, Internet service provider,
            referring/exit pages, and date/time stamps.
            <br />- “Web beacons”, “tags”, and “pixels” are electronic files
            used to record information about how you browse the Site.
          </Paragraph>

          <Paragraph style={{fontFamily: Theme.customFont.custFontNormal}}>
            Additionally when you make a purchase or attempt to make a purchase
            through the Site, we collect certain information from you, including
            your name, billing address, shipping address, payment information
            (including credit card numbers and PayPal information, email
            address, and phone number). We refer to this information as “Order
            Information”. We require this information to process your order.
          </Paragraph>

          <Paragraph style={{fontFamily: Theme.customFont.custFontNormal}}>
            When you opt into joining our Mailing List, we collect your name and
            email address for marketing purposes.
          </Paragraph>
          <Paragraph style={{fontFamily: Theme.customFont.custFontNormal}}>
            When we talk about “Personal Information” in this Privacy Policy, we
            are talking both about Device Information and Order Information.
          </Paragraph>

          <Title style={{fontFamily: Theme.customFont.custFontNormal}} level={4}>How do we use your personal information?</Title>
          <Paragraph style={{fontFamily: Theme.customFont.custFontNormal}}>
            We use the Order Information that we collect generally to fulfill
            any orders placed through the Site (including processing your
            payment information, arranging for shipping, and providing you with
            invoices and/or order confirmations). Additionally, we use this
            Order Information to:
            <br />
            - Communicate with you; <br />
            - Screen our orders for potential risk or fraud; and <br />- When in
            line with the preferences you have shared with us, provide you with
            information or advertising relating to our products or services.
          </Paragraph>

          <Paragraph style={{fontFamily: Theme.customFont.custFontNormal}}>
            We use the Device Information that we collect to help us screen for
            potential risk and fraud (in particular, your IP address), and more
            generally to improve and optimize our Site (for example, by
            generating analytics about how our customers browse and interact
            with the Site, and to assess the success of our marketing and
            advertising campaigns).
          </Paragraph>

          <Paragraph style={{fontFamily: Theme.customFont.custFontNormal}}>
            Other uses of Device Information includes digital marketing
            including remarketing.
          </Paragraph>

          <Title style={{fontFamily: Theme.customFont.custFontNormal}} level={4}>Sharing you personal Information</Title>

          <Paragraph style={{fontFamily: Theme.customFont.custFontNormal}}>
            We share your Personal Information with third parties to help us use
            your Personal Information, as described above. For example, we use
            Google Analytics to help us understand how our customers use the
            Site -- you can read more about how Google uses your Personal
            Information here:{" "}
            <Link
              href="https://www.google.co.uk/intl/en/policies/privacy/"
              target="_blank"
            >
              https://www.google.co.uk/intl/en/policies/privacy/.
            </Link>{" "}
            You can also opt-out of Google Analytics here:
            <Link
              href=" https://tools.google.co.uk/dlpage/gaoptout"
              target="_blank"
            >
              {" "}
              https://tools.google.co.uk/dlpage/gaoptout.
            </Link>
          </Paragraph>

          <Paragraph style={{fontFamily: Theme.customFont.custFontNormal}}>
            Finally, we may also share your Personal Information to comply with
            applicable laws and regulations, to respond to a subpoena, search
            warrant or other lawful request for information we receive, or to
            otherwise protect our rights.
          </Paragraph>
          <Title style={{fontFamily: Theme.customFont.custFontNormal}} level={4}>Behavioural advertising </Title>

          <Paragraph style={{fontFamily: Theme.customFont.custFontNormal}}>
            As described above, we use your Personal Information to provide you
            with targeted advertisements or marketing communications we believe
            may be of interest to you. For more information about how targeted
            advertising works, you can visit the Network Advertising
            Initiative’s (“NAI”) educational page at{" "}
            <Link
              href=" http://www.networkadvertising.org/understanding-online-advertising/how-does-it-work"
              target="_blank"
            >
              {" "}
              http://www.networkadvertising.org/understanding-online-advertising/how-does-it-work.
            </Link>
          </Paragraph>

          <Paragraph style={{fontFamily: Theme.customFont.custFontNormal}}>
            You can opt out of targeted advertising by using the links below:
            <br />- Facebook and Instagram:{" "}
            <Link
              href="https://www.facebook.co.uk/settings/?tab=ads"
              target="_blank"
            >
              https://www.facebook.co.uk/settings/?tab=ads
            </Link>{" "}
            <br />- Google:{" "}
            <Link
              href="https://www.google.co.uk/settings/ads/anonymous"
              target="_blank"
            >
              https://www.google.co.uk/settings/ads/anonymous{" "}
            </Link>
          </Paragraph>
          <Title style={{fontFamily: Theme.customFont.custFontNormal}} level={4}>Do not track</Title>
          <Paragraph style={{fontFamily: Theme.customFont.custFontNormal}}>
            Please note that we do not alter our Site’s data collection and use
            practices when we see a Do Not Track signal from your browser.
          </Paragraph>
          <Title style={{fontFamily: Theme.customFont.custFontNormal}} level={4}>Your rights</Title>
          <Paragraph style={{fontFamily: Theme.customFont.custFontNormal}}>
            If you are a European resident, you have the right to access
            personal information we hold about you and to ask that your personal
            information be corrected, updated, or deleted. If you would like to
            exercise this right, please contact us through the contact
            information below.
          </Paragraph>
          <Paragraph style={{fontFamily: Theme.customFont.custFontNormal}}>
            Additionally, if you are a European resident we note that we are
            processing your information in order to fulfill contracts we might
            have with you (for example if you make an order through the Site),
            or otherwise to pursue our legitimate business interests listed
            above. Additionally, please note that your information will be
            transferred outside of Europe, including to Canada and the United
            States.
          </Paragraph>

          <Title style={{fontFamily: Theme.customFont.custFontNormal}} level={4}>Data retention </Title>
          <Paragraph style={{fontFamily: Theme.customFont.custFontNormal}}>
            When you place an order through the Site, we will maintain your
            Order Information for our records unless and until you ask us to
            delete this information.
          </Paragraph>
          <Title style={{fontFamily: Theme.customFont.custFontNormal}} level={4}>Changes</Title>
          <Paragraph style={{fontFamily: Theme.customFont.custFontNormal}}>
            We may update this privacy policy from time to time in order to
            reflect, for example, changes to our practices or for other
            operational, legal or regulatory reasons.
          </Paragraph>
          <Title style={{fontFamily: Theme.customFont.custFontNormal}} level={4}>Contact us </Title>
          <Paragraph style={{fontFamily: Theme.customFont.custFontNormal}}>
            For more information about our privacy practices, if you have
            questions, or if you would like to make a complaint, please contact
            us by e‑mail at{" "}
            <Link href="mailto:info@desibanter.co.UK">
              info@desibanter.co.UK
            </Link>
          </Paragraph>
          <Paragraph style={{fontFamily: Theme.customFont.custFontNormal}}>Many Thanks</Paragraph>
          <Paragraph style={{fontFamily: Theme.customFont.custFontNormal}}></Paragraph>
          <Paragraph style={{fontFamily: Theme.customFont.custFontNormal}} strong>Desi Banter</Paragraph>

          <Row>
            <Col>
              <Image
                src={require("../assets/images/logo.jpeg")}
                alt="Desi Banter Logo"
                style={{ width: 80, height: 80 }}
              />
            </Col>
          </Row>
        </Typography>
      </Col>
    </Row>
  );
};
