import React from "react";
import { Col, Image, List, Row, Table, Typography } from "antd";
import Theme from "../utils/Theme";
export const ShippingPolicy = () => {
  const { Title, Text, Paragraph, Link } = Typography;
  const bulletPoints = [
    "Whilst we make every effort to deliver all your goods in the agreed time, we will not be liable if we fail to do so in part or in full due to circumstances beyond our control including exceptionally severe weather conditions.",
    "It is the Customer’s responsibility to ensure that the correct and valid delivery address is provided for their Order.  Shipments delayed or lost due to an incorrect delivery address are the Customer’s responsibility.",
    "Where the Delivery Address is different to the Billing Address provided, the Customer takes full responsibility for the security of delivery at that address.",
    "Desi Banter Ltd are only able to investigate non-receipt of parcel claims on Signed for Services or where the Royal Mail Tracking Service indicates the parcel has not been delivered.  Customers are expected to co-operate with Cardology Ltd and / or Royal Mail to resolve the claim.",
    "We reserve the right to charge a re-delivery fee for orders returned as undeliverable due to no-one being present at the delivery address to sign for the parcel or where an order has been sent to an incorrect address and re-sent once the address has been corrected, or to withhold refund of the delivery charge if the order is subsequently cancelled.",
    "We may pass your telephone number and / or email address to the courier / postal service to assist in delivering your order.",
  ];

  const shippingData = [
    { orderValue: "Per Card", shippingCharge: "£2.00", delivery: "1 - 3 days" },
    { orderValue: "£25+", shippingCharge: "FREE", delivery: "1 - 3 days" },
  ];

  const columns = [
    {
      title: "Order Value",
      dataIndex: "orderValue",
      key: "orderValue",
    },
    {
      title: "Shipping Charge",
      dataIndex: "shippingCharge",
      key: "shippingCharge",
    },
    {
      title: "Delivery",
      dataIndex: "delivery",
      key: "delivery",
    },
  ];

  const shippingData2 = [
    { orderValue: "Per Card", shippingCharge: "£3.00", delivery: "3 - 5 days" },
    {
      orderValue: "£12.01 - £24.99",
      shippingCharge: "£4.20",
      delivery: "3 - 5 days",
    },
    {
      orderValue: "£25 - £49.99",
      shippingCharge: "£4.80",
      delivery: "3 - 5 days",
    },
    { orderValue: "£50+", shippingCharge: "FREE", delivery: "3 - 5 days" },
  ];

  const columns2 = [
    {
      title: "Order Value",
      dataIndex: "orderValue",
      key: "orderValue",
    },
    {
      title: "Shipping Charge",
      dataIndex: "shippingCharge",
      key: "shippingCharge",
    },
    {
      title: "Delivery",
      dataIndex: "delivery",
      key: "delivery",
    },
  ];

  const shippingData3 = [
    { orderValue: "Per Card", shippingCharge: "£3.80", delivery: "5 - 7 days" },
    {
      orderValue: "£12.01 - £24.99",
      shippingCharge: "£5.50",
      delivery: "5 - 7 days",
    },
    {
      orderValue: "£25 - £49.99",
      shippingCharge: "£7.40",
      delivery: "5 - 7 days",
    },
    { orderValue: "£50+", shippingCharge: "FREE", delivery: "5 - 7 days" },
  ];

  const columns3 = [
    {
      title: "Order Value",
      dataIndex: "orderValue",
      key: "orderValue",
    },
    {
      title: "Shipping Charge",
      dataIndex: "shippingCharge",
      key: "shippingCharge",
    },
    {
      title: "Delivery",
      dataIndex: "delivery",
      key: "delivery",
    },
  ];

  return (
    <Row justify="center" style={{ padding: "20px" }}>
      <Col xs={24} md={18} lg={16}>
        <Typography>
          <Title style={{fontFamily: Theme.customFont.custFontNormal}} level={1}>SHIPPING POLICY</Title>

          <Paragraph style={{fontFamily: Theme.customFont.custFontNormal}}>
            Thank you for visiting and shopping at{" "}
            <Link href="www.desibanter.co.uk" target="_blank">
              www.desibanter.co.uk
            </Link>
          </Paragraph>
          <Paragraph style={{fontFamily: Theme.customFont.custFontNormal}}>
            Following are the terms and conditions that constitute our Shipping
            Policy.
          </Paragraph>
          <Title style={{fontFamily: Theme.customFont.custFontNormal}} level={2}>DOMESTIC SHIPPING POLICY</Title>
          <Title style={{fontFamily: Theme.customFont.custFontNormal}} level={4}>Shipment processing time</Title>
          <Paragraph style={{fontFamily: Theme.customFont.custFontNormal}}>
            Delivery will be made to the UK address specified by you.
          </Paragraph>

          <Paragraph style={{fontFamily: Theme.customFont.custFontNormal}}>
            All orders are guaranteed to be processed within 1-3 business days.
          </Paragraph>

          <Paragraph style={{fontFamily: Theme.customFont.custFontNormal}}>
            We always make <b>reasonable</b> endeavours to dispatch orders as
            fast as possible. Orders are not shipped or delivered on weekends or
            national holidays.
          </Paragraph>
          <Title style={{fontFamily: Theme.customFont.custFontNormal}} level={4}>Personalised Orders</Title>
          <Paragraph style={{fontFamily: Theme.customFont.custFontNormal}}>
            Orders which contain a personalisation request (request to add a
            message and deliver directly to the recipient) may be subject to
            additional processing time. For these orders, we aim to process them
            within 2 - 4 business days.
          </Paragraph>
          <Paragraph style={{fontFamily: Theme.customFont.custFontNormal}}>
            If there will be a significant delay in shipment of your order, we
            will contact you via email or telephone.
          </Paragraph>
          <br />
          <br />
          <Title style={{fontFamily: Theme.customFont.custFontNormal}} level={4}>Delivery Service:</Title>
          <Title style={{fontFamily: Theme.customFont.custFontNormal}} level={4}>Royal Mail Delivery Times:</Title>
          <Paragraph style={{fontFamily: Theme.customFont.custFontNormal}}>
            UK - Usually 24/48 hours for delivery (but this is not guaranteed
            and can take upto 10 working days)
          </Paragraph>
          <Title style={{fontFamily: Theme.customFont.custFontNormal}} level={2}>INTERNATIONAL SHIPPING POLICY</Title>
          <Paragraph style={{fontFamily: Theme.customFont.custFontNormal}}>
            International deliveries may take longer to reach you than normal
            due to the impact o the Covid-19 pandemic and its effect on national
            shipping carriers.
          </Paragraph>

          <Paragraph style={{fontFamily: Theme.customFont.custFontNormal}}>
            We use Royal Mail delivery estimates to advise customers of shipping
            times internationally.
          </Paragraph>

          <Title style={{fontFamily: Theme.customFont.custFontNormal}} level={4}>Shipment processing time</Title>
          <Paragraph style={{fontFamily: Theme.customFont.custFontNormal}}>
            Delivery will be made to the UK address specified by you.
          </Paragraph>

          <Paragraph style={{fontFamily: Theme.customFont.custFontNormal}}>
            All orders are guaranteed to be processed within 1-3 business days.
          </Paragraph>

          <Paragraph style={{fontFamily: Theme.customFont.custFontNormal}}>
            We always make <b>reasonable</b> endeavours to dispatch orders as
            fast as possible. Orders are not shipped or delivered on weekends or
            national holidays.
          </Paragraph>
          <Title style={{fontFamily: Theme.customFont.custFontNormal}} level={4}>Personalised Orders</Title>
          <Paragraph style={{fontFamily: Theme.customFont.custFontNormal}}>
            Orders which contain a personalisation request (request to add a
            message and deliver directly to the recipient) may be subject to
            additional processing time. For these orders, we aim to process them
            within 2 - 4 business days.
          </Paragraph>

          <Paragraph style={{fontFamily: Theme.customFont.custFontNormal}}>
            If there will be a significant delay in shipment of your order, we
            will contact you via email or telephone.
          </Paragraph>
          <Title style={{fontFamily: Theme.customFont.custFontNormal}} level={4}>Delivery Service:</Title>

          <Paragraph style={{fontFamily: Theme.customFont.custFontNormal}}>
            All orders will be delivered International Standard Class by Royal
            Mail.
          </Paragraph>

          <Paragraph style={{fontFamily: Theme.customFont.custFontNormal}}>
            We currently do not offer an express delivery service.
          </Paragraph>
          <Title style={{fontFamily: Theme.customFont.custFontNormal}} level={4}>Royal Mail Delivery Times:</Title>
          <Paragraph style={{fontFamily: Theme.customFont.custFontNormal}}>
            <i>Europe</i> - Usually 3 - 5 days (but this is not guaranteed and
            can take longer. This is also dependant on the National Postal
            Service within the destination country).
          </Paragraph>
          <Paragraph style={{fontFamily: Theme.customFont.custFontNormal}}>
            <i>Rest Of The World</i> - Usually 5 - 7 days (but this is not
            guaranteed and can take longer. This is also dependant on the
            National Postal Service within the destination country).
          </Paragraph>
          <Title style={{fontFamily: Theme.customFont.custFontNormal}} level={4}>Customs, Duties and Taxes</Title>
          <Paragraph style={{fontFamily: Theme.customFont.custFontNormal}}>
            Desi Banter Ltd is not responsible for any customs and taxes applied
            to your order. All fees imposed during or after shipping are the
            responsibility of the customer (tariffs, taxes, etc.).
          </Paragraph>
          <Title style={{fontFamily: Theme.customFont.custFontNormal}} level={4} underline>
            Please Note:
          </Title>

          <List
            bordered
            dataSource={bulletPoints}
            renderItem={(item) => (
              <List.Item>
                <Paragraph style={{ margin: 0, fontFamily: Theme.customFont.custFontNormal }}>{item}</Paragraph>
              </List.Item>
            )}
          />
          <Title style={{fontFamily: Theme.customFont.custFontNormal}} level={1}>SHIPPING RATES</Title>
          <Paragraph style={{fontFamily: Theme.customFont.custFontNormal}}>
            Shipping charges for your order will be calculated and displayed at
            checkout.
          </Paragraph>

          <Title style={{fontFamily: Theme.customFont.custFontNormal}} level={1}> UNITED KINGDOM</Title>
          <Table
            dataSource={shippingData}
            columns={columns}
            pagination={false} // Optional: Disable pagination if you don't want it
          />
          <Title style={{fontFamily: Theme.customFont.custFontNormal}} level={1}>EUROPE</Title>
          <Table
            dataSource={shippingData2}
            columns={columns2}
            pagination={false} // Optional: Disable pagination if you don't want it
          />
          <Title style={{fontFamily: Theme.customFont.custFontNormal}} level={1}>REST OF THE WORLD</Title>
          <Table
            dataSource={shippingData3}
            columns={columns3}
            pagination={false} // Optional: Disable pagination if you don't want it
          />
          <br />
          <br />
          <Paragraph style={{fontFamily: Theme.customFont.custFontNormal}}>Many Thanks</Paragraph>
          <Paragraph style={{fontFamily: Theme.customFont.custFontNormal}}></Paragraph>
          <Paragraph style={{fontFamily: Theme.customFont.custFontNormal}} strong>Desi Banter</Paragraph>

          <Row>
            <Col>
              <Image
                src={require("../assets/images/logo.jpeg")}
                alt="Desi Banter Logo"
                style={{ width: 80, height: 80 }}
              />
            </Col>
          </Row>
        </Typography>
      </Col>
    </Row>
  );
};
