import React, { useEffect, useState } from "react";
import {
  MDBBtn,
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBCardImage,
  MDBInput,
  MDBIcon,
  MDBCheckbox,
} from "mdb-react-ui-kit";
import { message, Typography } from "antd";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { clearSignupError, signup } from "../redux/counter/AuthSlice";
import Theme from "../utils/Theme";

const InputWithError = ({
    id,
    label,
    type,
    value,
    onChange,
    error,
    icon,
  }) => (
    <div className="d-flex flex-column align-items-center mb-4">
      <div className="d-flex flex-row align-items-center mb-4">
        <MDBIcon fas icon={icon} size="lg" />
        <MDBInput
          label={label}
          labelStyle={{fontFamily: Theme.customFont.custFontNormal}}
          id={id}
          type={type}
          value={value}
          onChange={onChange}
        />
      </div>
      {error && (
        <Typography.Text style={{ color: 'red', display: 'block',fontFamily: Theme.customFont.custFontNormal }}>
          {error}
        </Typography.Text>
      )}
    </div>
  );
  

export const SignUp = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        password: '',
        mobile_number: '',
        subscribe: false,
      });
      const {isError,  signupError, isSucess } = useSelector(state=>state.auth);
      const [prevSignupError, setPrevSignupError] = useState(null);
      const [error, setError] = useState("");
    const dispatch = useDispatch()
      const navigate = useNavigate();

      const [errors, setErrors] = useState({});

     
      useEffect(() => {
        // Check if signupError has changed and is not null, then set the error state
        if (signupError && signupError) {
          setError(signupError);
          message.error(`${signupError}`, 5)
    
          // Dispatch the action to clear the signupError
          dispatch(clearSignupError());
        } if( isSucess){
          setFormData({
            name: '',
            email: '',
            password: '',
            mobile_number: '',
            
          });
          message.success('Sigup Successfully', 4)
          navigate('/login')
        }
      }, [signupError, dispatch, isSucess]);
      const handleChange = (e) => {
        const { id, value, type, checked } = e.target;
    
        setFormData((prevData) => ({
          ...prevData,
          [id]: type === 'checkbox' ? checked : value,
        }));
      };
      const handleSubmit =  (e) => {
        e.preventDefault();
  
        // Validation logic
        const newErrors = {};
        if (!formData.name.trim()) {
          newErrors.name = 'Name is required';
        }
    
        if (!formData.email.trim()) {
          newErrors.email = 'Email is required';
        } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
          newErrors.email = 'Invalid email address';
        }
    
        if (!formData.password.trim()) {
          newErrors.password = 'Password is required';
        }
    
        // if (formData.password !== formData.mobile_number) {
        //   newErrors.mobile_number = 'Passwords do not match';
        // }
    
        // Set errors or perform submission logic
        if (Object.keys(newErrors).length > 0) {
          setErrors(newErrors);
        } else {
          // Submit the form or perform other actions
        
          const params = {
            name:formData.name,
            email: formData.email,
            mobile_number: formData.mobile_number,
            password: formData.password,
            
          };
          dispatch(signup(params));
         
          // Reset the form and state
          
          setErrors({});
          
          
          
          
        }
      
      };
      return (
        <MDBContainer fluid>
          <MDBCard className='text-black m-5' style={{ borderRadius: '25px' }}>
            <MDBCardBody style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <MDBRow style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <MDBCol
                  md='10'
                  lg='6'
                  className='order-2 order-lg-1 d-flex flex-column align-items-center'
                >
                  <p className='text-center h1 fw-bold mb-5 mx-1 mx-md-4 mt-4' style={{fontFamily: Theme.customFont.custFontNormal}}>
                    Sign up
                  </p>
    
                  <InputWithError
                    id='name'
                    label='Your Name'
                    type='text'
                    value={formData.name}
                    onChange={handleChange}
                    error={errors.name}
                    icon='user me-3'
                  />
    
                  <InputWithError
                    id='email'
                    label='Your Email'
                    type='email'
                    value={formData.email}
                    onChange={handleChange}
                    error={errors.email}
                    icon='envelope me-3'
                  />

                  {/* Add InputWithError for mobile number */}
              <InputWithError
                id='mobile_number'
                label='Your Mobile Number'
                type='tel'  // Use 'tel' type for phone numbers
                value={formData.mobile_number}
                onChange={handleChange}
                error={errors.mobile_number}
                icon='phone me-3'
              />
    
                  <InputWithError
                    id='password'
                    label='Password'
                    type='password'
                    value={formData.password}
                    onChange={handleChange}
                    error={errors.password}
                    icon='user-lock me-3'
                  />
    
                  {/* <div className='mb-4'>
                    <MDBCheckbox
                      name='subscribe'
                      value=''
                      id='subscribe'
                      label='Subscribe to our newsletter'
                      checked={formData.subscribe}
                      onChange={handleChange}
                    />
                  </div> */}
                  <MDBBtn
                    className='mb-4'
                    size='lg'
                    onClick={handleSubmit}
                    style={{fontFamily: Theme.customFont.custFontNormal}}
                  >
                    Register
                  </MDBBtn>
                  
                </MDBCol>
                <MDBCol md='10' lg='6' className='order-1 order-lg-2'>
                  {/* Responsive image rendering */}
                  <div className='d-md-flex align-items-md-start'>
                    <MDBCardImage
                      src={require('../assets/images/logo.jpeg')}
                      fluid
                    />
                  </div>
                </MDBCol>
              </MDBRow>
            </MDBCardBody>
          </MDBCard>
        </MDBContainer>
  );
};
