import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import {API} from '../../api/index'

const initialState = {
    productsData: null,
    isLoading: false,
    isSucess: false,
    isError: false
}

export const getAllProducts = createAsyncThunk('getAllProducts', async ( thunkApi) => {
    
    try {
      const response = await API.get('items/list');
      return response.data;
    } catch (error) {
      // Instead of returning the entire error object, throw a new Error
      throw new Error(error.message);
    }
  });

const ProductSlice = createSlice({
    name: 'ProductSlice',
    initialState,
    reducers:{
    },
    extraReducers:(builder)=>{
builder.addCase(getAllProducts.pending, (state)=>{
state.isLoading = true
})
builder.addCase(getAllProducts.fulfilled,(state, action)=>{
    state.isLoading = false;
    state.isSucess = true;
    state.productsData = action.payload;
})
builder.addCase(getAllProducts.rejected,(state, action)=>{
    state.isLoading = false;
    state.isError = true;
})

    }
})



export default ProductSlice.reducer;