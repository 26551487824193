//colors
const color = {
    
    red: '#db2828',
   
  };
  
  //other
  
  const customFont = {
    custFontNormal: "Comic Sans MS",
    
  }
  
  export default {color, customFont }