import React, { useState, useEffect } from "react";
import {
  Form,
  Input,
  Button,
  Card,
  Space,
  Upload,
  Row,
  Col,
  Radio,
  message,
  Spin,
  Avatar,
} from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { useSelector, useDispatch } from "react-redux";
import { clearProfile, setProfileData } from "../redux/counter/ProfileSlice";
import Notification from "../components/Notification";
import Theme from "../utils/Theme";
import UserProfileModal from "../components/UserProfileModal";
import { useNavigate } from "react-router";
import { API } from "../api";
import axios from "axios";

const ProfilePage = () => {
  const { profileData } = useSelector((state) => state.userProfile);
  const { userData } = useSelector((state) => state.auth);
  const [isEditing, setIsEditing] = useState(false);
  const [dataSaved, setdataSaved] = useState(false);
  const [fileList, setFileList] = useState([]);
  
  const [initialFileList, setInitialFileList] = useState([]);
  const [loading, setLoading] = useState(false); // Add loading state
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const getProfileData = async () => {
    //setLoading(true);
    try {
      const res = await axios.get(
        `https://admin.desibanter.co.uk/api/profile?customer_id=${userData.data._id}`
      );
      dispatch(setProfileData(res.data));
    } catch (error) {
      console.log(error);
    } finally {
      setTimeout(() => {
        setLoading(false);
      }, 3000);
      if (loading === false) {
        return;
      } else {
        message.success("Profile saved successfully", 5);
      }
      
    }
  };

  useEffect(() => {
    if (userData.data) {
      getProfileData();
    }
  }, [userData, form]);

  useEffect(() => {
    if (dataSaved) {
      setTimeout(() => {
        getProfileData();
        setdataSaved(false);
      }, 2000);
    }
    // setdataSaved(false)
  }, [dataSaved]);

  // useEffect(() => {
  //   if (profileData?.data) {
  //     form.setFieldsValue(profileData?.data);
  
  //     // Set initialFileList when the component mounts
  //     const fileListFromProfile = profileData?.data?.profile_image
  //       ? [
  //           {
  //             uid: "-1",
  //             name: "Profile_image2",
  //             status: "done",
  //             url: profileData?.data?.profile_image,
  //           },
  //         ]
  //       : [];
  //     setInitialFileList(fileListFromProfile);
  
  //     // Set fileList only if the user hasn't selected a new image
  //     if (fileList.length === 0) {
  //       setFileList(fileListFromProfile);
  //     }
  //   }
    
  // }, [profileData?.data, form,]);

  useEffect(() => {
    if (profileData?.data) {
      form.setFieldsValue(profileData?.data);
  
      // Set initialFileList when the component mounts
      const fileListFromProfile = profileData?.data?.profile_image
        ? [
            {
              uid: "-1",
              name: "Profile_image2",
              status: "done",
              url: profileData?.data?.profile_image,
            },
          ]
        : [];
      setInitialFileList(fileListFromProfile);
  
      // Set fileList only if the user hasn't selected a new image
      if (fileList.length === 0) {
        setFileList(fileListFromProfile);
      }
    }
  }, [profileData, form]);
  

  useEffect(() => {
    if (!profileData) {
      form.resetFields();
      setIsEditing(true);
      //setFileList([]);
    }
  }, [profileData, form]);

  const handleEditToggle = () => {
  
    setIsEditing(!isEditing);
    if (!isEditing) {
      setFileList(initialFileList);
    }
   
  };

  const handelCancel = () => {
    navigate("/");
  };


  const acceptedImageTypes = [
    "image/png",
    "image/jpeg",
    "image/jpg",
    "image/gif",
    "image/svg+xml",
  ];

  const beforeUpload = (file) => {
    return new Promise((resolve, reject) => {
      const isImage = acceptedImageTypes.includes(file.type);
  
      if (!isImage) {
        message.error(`${file.name} is not a valid image file`);
        resolve(Upload.LIST_IGNORE);
      }
  
      if (isImage) {
        resolve(file);
      }
    });
  };
  

  const onChange = ({ fileList }) => {
    // Ensure that fileList is always an array
    const updatedFileList = Array.isArray(fileList) ? fileList : [];
  
    // Filter out non-image files
    const filteredFileList = updatedFileList.filter((file) => {
      const isImage = acceptedImageTypes.includes(file.type);
      return isImage;
    });
  
    // Update fileList and initialFileList
    setFileList(filteredFileList);
    if (filteredFileList.length > 0) {
      setInitialFileList(filteredFileList);
    }
  };

  const handleSave = async () => {
    try {
      await form.validateFields();
      const values = form.getFieldsValue();
      setIsEditing(false);
      setLoading(true);
      const formData = new FormData();
      const payload = {
        customer_id: userData?.data?._id,
        gender: values.gender,
        address: values.address,
        country: values.country,
        postal_code: values.postal_code,
        city: values.city,
        town: values.town,
        description: values?.comment ? values.comment : "",
      };
      formData.append("data", JSON.stringify(payload));
      if (fileList.length > 0 && fileList[0]?.originFileObj) {
        formData.append(
          "images",
          fileList[0]?.originFileObj,
          fileList[0]?.originFileObj.name
        );
      }

      const response = await axios.post(
        "https://admin.desibanter.co.uk/api/profile",
        formData
      );
      if (response?.data) {
        setdataSaved(true);
      }
      
    } catch (error) {
      console.error("Validation failed:", error);
    }
  };

  const renderFormFields = () => {
    return (
      <div style={{ width: "100%", marginTop: 20 }}>
        <Form
          form={form}
          labelCol={{ span: 8, flex: "0 0 20%" }}
          wrapperCol={{ span: 16 }}
        >
          <Form.Item
            label="Name"
            style={{ fontFamily: Theme.customFont.custFontNormal }}
            name="name"
            rules={[
              { required: true, message: "Please enter your first name" },
            ]}
          >
            <Input disabled={true} />
          </Form.Item>

          <Form.Item
            label="Email"
            style={{ fontFamily: Theme.customFont.custFontNormal }}
            name="email"
            rules={[
              { required: true, message: "Please enter your email address" },
              { type: "email", message: "Please enter a valid email address" },
            ]}
          >
            <Input disabled={true} />
          </Form.Item>

          <Form.Item
            label="Phone No"
            style={{ fontFamily: Theme.customFont.custFontNormal }}
            name="mobile_number"
            rules={[
              { required: true, message: "Please enter your phone number" },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Address"
            style={{ fontFamily: Theme.customFont.custFontNormal }}
            name="address"
            rules={[{ required: true, message: "Please enter your address" }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Town"
            style={{ fontFamily: Theme.customFont.custFontNormal }}
            name="town"
            rules={[{ required: true, message: "Please enter your town" }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="City"
            style={{ fontFamily: Theme.customFont.custFontNormal }}
            name="city"
            rules={[{ required: true, message: "Please enter your city" }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Postal Code"
            style={{ fontFamily: Theme.customFont.custFontNormal }}
            name="postal_code"
            rules={[
              { required: true, message: "Please enter your postal code" },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Country"
            style={{ fontFamily: Theme.customFont.custFontNormal }}
            name="country"
            rules={[{ required: true, message: "Please enter your country" }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Comment"
            name="comment"
            style={{ fontFamily: Theme.customFont.custFontNormal }}
          >
            <Input.TextArea placeholder="Optional" />
          </Form.Item>

          <Form.Item
            label="Gender"
            style={{ fontFamily: Theme.customFont.custFontNormal }}
            name="gender"
            rules={[{ required: true, message: "Please select your gender" }]}
          >
            <Radio.Group>
              <Radio value="male">Male</Radio>
              <Radio value="female">Female</Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item
            label="Profile Image"
            name='profile_image2'
            style={{ fontFamily: Theme.customFont.custFontNormal }}
            valuePropName="fileList"
            getValueFromEvent={(e) => e.fileList}
          >
            <Upload
              listType="picture"
              fileList={fileList}
              onChange={onChange}
              defaultFileList={initialFileList}
              //onPreview={onPreview}
              beforeUpload={(e) => beforeUpload(e)}
            >
              { fileList.length >= 1 ? null :
                <Button icon={<UploadOutlined />}>Upload</Button>
              }
            </Upload>
          </Form.Item>
        </Form>
      </div>
    );
  };
  
  return (
    <div style={{ position: "relative", minHeight: "100vh" }}>
      {loading && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.5)", // semi-transparent white
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            zIndex: 9999,
          }}
        >
          <Spin tip={'Loading...'} size="large" />
        </div>
      )}
      <Row justify="center" align={"middle"}>
        <Col xs={22} sm={20} md={16} lg={14} xl={12}>
          <Card
            title="Your Profile"
            headStyle={{
              backgroundColor: "red",
              color: "white",
              fontWeight: "bold",
            }}
            style={{
              margin: "auto",
              marginTop: 70,
              marginBottom: 200,
              borderColor: "transparent",
              boxShadow: "0px 0px 10px 0px",
            }}
          >
            {isEditing || !profileData?.data?.address ? (
              renderFormFields()
            ) : (
              <Space
                direction="vertical"
                style={{ width: "100%", paddingLeft: 25, paddingTop: 20 }}
              >
                <p style={{ fontFamily: Theme.customFont.custFontNormal }}>
                  <strong>Name:</strong> {profileData?.data?.name}
                </p>
                <p style={{ fontFamily: Theme.customFont.custFontNormal }}>
                  <strong>Email:</strong> {profileData?.data?.email}
                </p>
                <p style={{ fontFamily: Theme.customFont.custFontNormal }}>
                  <strong>Phone No:</strong> {profileData?.data?.mobile_number}
                </p>
                <p style={{ fontFamily: Theme.customFont.custFontNormal }}>
                  <strong>Address:</strong> {profileData?.data?.address}
                </p>

                <p style={{ fontFamily: Theme.customFont.custFontNormal }}>
                  <strong>Town:</strong> {profileData?.data?.town}
                </p>

                <p style={{ fontFamily: Theme.customFont.custFontNormal }}>
                  <strong>City:</strong> {profileData?.data?.city}
                </p>
                <p style={{ fontFamily: Theme.customFont.custFontNormal }}>
                  <strong>PostalCode:</strong> {profileData?.data?.postal_code}
                </p>

                <p style={{ fontFamily: Theme.customFont.custFontNormal }}>
                  <strong>Country:</strong> {profileData?.data?.country}
                </p>

                {profileData?.data?.description != "" ? (
                  <p style={{ fontFamily: Theme.customFont.custFontNormal }}>
                    <strong>Comment:</strong> {profileData?.data?.description}
                  </p>
                ) : null}
               
                <p style={{ fontFamily: Theme.customFont.custFontNormal }}>
                  <strong>Gender:</strong> {profileData?.data?.gender}
                </p>

                
                { profileData?.data?.profile_image !== '' ? <p
                  style={{
                    fontFamily: Theme.customFont.custFontNormal,
                    display: "flex",
                  }}
                >
                  <strong>Profile Image:</strong>{" "}
                  {profileData?.data?.profile_image && (
                    <Avatar size={"large"}
                      src={profileData?.data?.profile_image}
                      alt="Profile"
                      style={{
                        marginLeft: 10,

                      }}
                    />
                  )}
                </p> : null }
              </Space>
            )}

            <div style={{ marginTop: 16, marginLeft: 20, paddingBottom: 10 }}>
              {isEditing || !profileData?.data?.address  ? (
                <>
                  <Button
                    onClick={handleSave}
                    type="primary"
                    style={{
                      backgroundColor: "red",
                      color: "white",
                      fontWeight: "bold",
                      textSpace: 5,
                      fontFamily: Theme.customFont.custFontNormal,
                    }}
                  >
                    Save
                  </Button>
                  <Button
                    onClick={handelCancel}
                    style={{
                      marginLeft: 8,
                      fontFamily: Theme.customFont.custFontNormal,
                    }}
                  >
                    Cancel
                  </Button>
                </>
              ) : (
                <>
                  <Button
                    onClick={handleEditToggle}
                    type="primary"
                    style={{
                      backgroundColor: "red",
                      color: "white",
                      fontWeight: "bold",
                      textSpace: 5,
                      fontFamily: Theme.customFont.custFontNormal,
                    }}
                  >
                    Edit
                  </Button>
                </>
              )}
            </div>
          </Card>
          {/* <Notification open={open} /> */}
        </Col>
      </Row>
    </div>
  );
};

export default ProfilePage;
