import React, { useState, useEffect } from 'react'
import "../App.css";
import {  Row, Col, Select, AutoComplete } from "antd";
import { SearchOutlined, DownOutlined } from "@ant-design/icons";
import Theme from '../utils/Theme';
import { API } from '../api';
export const DropDowns = ({data, onCategorySelect, onSearchSelect, uniqueCategories}) => {
  const { Option } = Select;

  const [selectedOptions, setSelectedOptions] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [hoveredOption, setHoveredOption] = useState(null);
  const [searchText, setSearchText] = useState('');

  const handleOptionHover = (value) => {
    setHoveredOption(value);
  };

  const handleCategorySelect = (value) => {
    setSelectedCategory(value);
    onCategorySelect(value);
    setSelectedOptions([]);
    setSearchText('');
    onSearchSelect('');
  };
  const search = (searchText) => {
    // const matchingOptions = data.data.filter((option) =>
    //   option.title.toLowerCase().includes(searchText.toLowerCase())
    // );
    const matchingOptions = data.data.filter((option) =>
      option.title.toLowerCase().startsWith(searchText.toLowerCase())
    );
    onSearchSelect(searchText);
    if (matchingOptions.length > 0  ) {
      setSelectedOptions(matchingOptions);
      // setSelectedCategory(null)
    } else {
      setSelectedOptions([]);
    }
  };
  // const uniqueCategories = ['All',...new Set(categoryData?.data?.map((option) => option.name
  //   ))];
  return (
    <Row
      gutter={16}
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: 20,
        paddingLeft: 10,
        paddingRight: 10,
      }}
    >
      <Col xs={8} sm={6} md={6} lg={6} xl={4}>
        {/* Left Select */}
        <Select
  placeholder="Categories"
  value={selectedCategory}
  onChange={handleCategorySelect}
  style={{ width: '100%', fontFamily: Theme.customFont.custFontNormal, }}
  bordered={false}
  className="custom-select"
  suffixIcon={<DownOutlined style={{ color: 'rgb(241, 60, 60)' }} />}
>

  {uniqueCategories.map((category) => (
    <Option
      key={category}
      value={category}
      onMouseEnter={() => handleOptionHover(category)}
      onMouseLeave={() => handleOptionHover(null)}
      style={{
        backgroundColor:
          hoveredOption === category ? 'rgb(241, 60, 60)' : 'white',
          fontFamily: Theme.customFont.custFontNormal
      }}
    >
      {category}
    </Option>
  ))}
</Select>
      </Col>
      <Col xs={12} sm={10} md={10} lg={10} xl={10}>
        {/* Right AutoComplete */}
        <AutoComplete
          className="custom-select"
          options={
            selectedOptions
              ? selectedOptions.map((option) => ({ value: option.title }))
              : []
          }
          style={{ width: '100%' }}
          filterOption={true}
          value={searchText}
          onChange={(val) => setSearchText(val)}
          onSelect={(value) => {
            const selectedObjects = data.data.filter(
              (option) => option.title === value
            );
            // Now you can save the selected objects in the state or perform any other action
            setSelectedOptions(selectedObjects);
            search(value)
          }}
          placeholder="Search here ...."
          onSearch={(val) => {
            search(val);
          }}
          suffixIcon={
            <SearchOutlined
              style={{ color: 'rgb(241, 60, 60)', fontSize: 20 }}
            />
          }
        />
      </Col>
    </Row>
  );
};