import { Col, Image, List, Row, Typography } from "antd";
import React from "react";
import Theme from "../utils/Theme";

export const TeramsandCondition = () => {
  const { Title, Text, Paragraph, Link } = Typography;

  const bulletPoints = [
    "Whilst we make every effort to deliver all your goods in the agreed time, we will not be liable if we fail to do so in part or in full due to circumstances beyond our control including exceptionally severe weather conditions.",
    "It is the Customer’s responsibility to ensure that the correct and valid delivery address is provided for their Order.  Shipments delayed or lost due to an incorrect delivery address are the Customer’s responsibility.",
    "Where the Delivery Address is different to the Billing Address provided, the Customer takes full responsibility for the security of delivery at that address.",
    "Desi Banter Ltd are only able to investigate non-receipt of parcel claims on Signed for Services or where the Royal Mail Tracking Service indicates the parcel has not been delivered.  Customers are expected to co-operate with Cardology Ltd and / or Royal Mail to resolve the claim.",
    "We reserve the right to charge a re-delivery fee for orders returned as undeliverable due to no-one being present at the delivery address to sign for the parcel or where an order has been sent to an incorrect address and re-sent once the address has been corrected, or to withhold refund of the delivery charge if the order is subsequently cancelled.",
    "We may pass your telephone number and / or email address to the courier / postal service to assist in delivering your order.",
  ];
  return (
    <Row justify="center" style={{ padding: "20px" }}>
      <Col xs={24} md={18} lg={16}>
        <Typography>
          <Title level={2} style={{fontFamily: Theme.customFont.custFontNormal}}>TERMS AND CONDITIONS</Title>

          <Paragraph style={{fontFamily: Theme.customFont.custFontNormal}}>
            Please read these terms and conditions carefully as they set out the
            terms under which{" "}
            <Link href="http://desibanter.co.uk/" target="_blank" underline>
              desibanter.co.uk
            </Link>{" "}
            (the “Website”) sells and otherwise makes products available to you
            (“Terms and Conditions”). Desi Banter Limited is a company
            registered at -- . Company
            registration 14938031 (“we” and “us”; and “our” shall be construed
            accordingly). By purchasing products available on this Website, you
            as the customer will transact with us.
          </Paragraph>

          <Paragraph style={{fontFamily: Theme.customFont.custFontNormal}}>
            These Terms and Conditions should be read alongside, and are in
            addition to, our privacy policy (“Privacy Policy”), Returns Policy
            ("Returns Policy") and our Terms of Use (“Terms of Service”).
          </Paragraph>

          <Paragraph style={{fontFamily: Theme.customFont.custFontNormal}}>
            We recommend that you print off and keep a copy of these Terms and
            Conditions for your future reference. By purchasing any product
            available on this Website, you agree that you have read, understood
            and agree to these Terms and Conditions, the Privacy Policy and the
            Terms of Use in their entirety (including as amended from time to
            time). If you do not agree to these Terms and Conditions, you must
            not purchase any product available on this Website.
          </Paragraph>

          <Paragraph style={{fontFamily: Theme.customFont.custFontNormal}}>
            By placing an order through our Website, you warrant that you are
            legally capable of entering into binding contracts and you are at
            least 18 years old.
          </Paragraph>

          <Title style={{fontFamily: Theme.customFont.custFontNormal}} level={4}>Prices</Title>

          <Paragraph style={{fontFamily: Theme.customFont.custFontNormal}}>
            All prices are expressed inclusive of any VAT payable. Prices can
            change and we endeavour to keep our prices updated at all times.
          </Paragraph>

          <Title style={{fontFamily: Theme.customFont.custFontNormal}} level={4}> Returns Policy</Title>

          <Paragraph style={{fontFamily: Theme.customFont.custFontNormal}}>
            We want you to love your purchase. If you are not happy, please get
            in touch at{" "}
            <Link href="mailto:info@desibanter.co.uk">info@desibanter.co.uk</Link>{" "}
            to discuss the issue and for our returns address.
          </Paragraph>

          <Paragraph style={{fontFamily: Theme.customFont.custFontNormal}}>
            We are unable to accept returns, or provide refunds, in respect of
            personalised products, unless the product is defective or we are
            otherwise in breach of our agreement with you and are obliged to do
            so.
          </Paragraph>

          <Paragraph style={{fontFamily: Theme.customFont.custFontNormal}}>
            To obtain a refund you must return the product(s) to us in the same
            condition in which you received them, including the packing slip. If
            you don’t have the packing slip, please include your order number in
            any correspondence.
          </Paragraph>

          <Paragraph style={{fontFamily: Theme.customFont.custFontNormal}}>
            You are responsible for return postage charges and we recommend you
            get proof of posting or use a trackable service to do so.
          </Paragraph>

          <Paragraph style={{fontFamily: Theme.customFont.custFontNormal}}>
            We will examine the returned product(s) and will notify you of your
            refund via e-mail within a reasonable period of time. We will
            usually process the refund due to you as soon as possible and, in
            any case, within 14 days of the day we confirmed to you via e-mail
            that you were entitled to a refund for the defective product.
            Products returned by you because of a defect will be refunded in
            full, including a refund of the delivery charges for sending the
            item to you (if you were charged for this in the original order).
          </Paragraph>

          <Paragraph style={{fontFamily: Theme.customFont.custFontNormal}}>
            We will either exchange or fully refund the item/s in question,
            within 14 days of you notifying us of the issue (except Bespoke or
            Wholesale orders).
          </Paragraph>

          <Paragraph style={{fontFamily: Theme.customFont.custFontNormal}}>
            Refunds will be made via electronic transfer (via the original
            payment method for your order).
          </Paragraph>

          <Title style={{fontFamily: Theme.customFont.custFontNormal}} level={4}> Order Cancellation Policy</Title>

          <Paragraph style={{fontFamily: Theme.customFont.custFontNormal}}>
            Unfortunately, we cannot honour cancellation requests. Each order is
            taken from our stock immediately after it arrives, to ensure we can
            post it out to you as quickly as possible. Please refer to our
            returns policy.
          </Paragraph>

          <Title style={{fontFamily: Theme.customFont.custFontNormal}} level={4}> Order Cancellation Policy</Title>

          <Paragraph style={{fontFamily: Theme.customFont.custFontNormal}}>
            Unfortunately, we cannot honour cancellation requests. Each order is
            taken from our stock immediately after it arrives, to ensure we can
            post it out to you as quickly as possible. Please refer to our
            returns policy.
          </Paragraph>

          <Title style={{fontFamily: Theme.customFont.custFontNormal}} level={4}>Availability</Title>

          <Paragraph style={{fontFamily: Theme.customFont.custFontNormal}}>
            If for any reason beyond our reasonable control, we are unable to
            supply a particular item, we will notify you as soon as possible. We
            will contact you to confirm that you are happy to proceed with the
            order and agree to wait for the original product(s) or would like to
            select an alternative product or get a refund for your order.
          </Paragraph>

          <Title style={{fontFamily: Theme.customFont.custFontNormal}} level={4}>Contract</Title>

          <Paragraph style={{fontFamily: Theme.customFont.custFontNormal}}>
            There will be no contract of any kind between you and us until
            payment for your goods is authorised by your bank or credit card
            issuer. At the moment that payment for the goods is authorised (and
            not before) a contract will be made between you and us (“Contract”).
            Authority for payment must be given at the time of the order.
            Payment for your order will be taken once stock availability has
            been confirmed. If for any reason the goods cannot be supplied then
            payment will be refunded in full within 5 working days from
            cancellation of the sale.
          </Paragraph>

          <Title style={{fontFamily: Theme.customFont.custFontNormal}} level={4}>Delivery Times</Title>

          <Paragraph style={{fontFamily: Theme.customFont.custFontNormal}}>
            Delivery will be made to the address specified by you. We aim to
            process any orders received, within 1 - 3 business days. We always
            make <b>reasonable</b> endeavours to dispatch orders as fast as
            possible.
          </Paragraph>

          <Paragraph style={{fontFamily: Theme.customFont.custFontNormal}}>
            All card orders will be delivered Second Class mail by Royal Mail.
            If the value of the order is above £50, we will post the order First
            Class.
          </Paragraph>
          <Title style={{fontFamily: Theme.customFont.custFontNormal}} level={4} underline>
            Royal Mail states:
          </Title>

          <Paragraph style={{fontFamily: Theme.customFont.custFontNormal}}>
            UK - Usually 24/48 hours for delivery (but this is not guaranteed
            and can take upto 10 working days)
          </Paragraph>

          <Paragraph style={{fontFamily: Theme.customFont.custFontNormal}}>
            Europe - Usually 3 - 5 days (but this is not guaranteed and can take
            longer. This is also dependant on the National Postal Service within
            the destination country).
          </Paragraph>

          <Paragraph style={{fontFamily: Theme.customFont.custFontNormal}}>
            Rest Of The World - Usually 5 - 7 days (but this is not guaranteed
            and can take longer. This is also dependant on the National Postal
            Service within the destination country).
          </Paragraph>

          <Title style={{fontFamily: Theme.customFont.custFontNormal}} level={4} underline>
            Please Note:
          </Title>

          <List
            bordered
            dataSource={bulletPoints}
            renderItem={(item) => (
              <List.Item>
                <Paragraph style={{ margin: 0, fontFamily: Theme.customFont.custFontNormal }}>{item}</Paragraph>
              </List.Item>
            )}
          />

          <Title style={{fontFamily: Theme.customFont.custFontNormal}} level={4}>European Orders</Title>

          <Paragraph style={{fontFamily: Theme.customFont.custFontNormal}}>
            We continue to welcome orders from EU consumers, where we offer our
            products to you on delivered at place (DAP) incoterms. This means
            you will be responsible for import clearance and any applicable
            local taxes or import duties.
          </Paragraph>

          <Paragraph style={{fontFamily: Theme.customFont.custFontNormal}}>
            If you are unsure if your order will incur a customs duty payment,
            please check the import specifications for your country.
          </Paragraph>

          <Title style={{fontFamily: Theme.customFont.custFontNormal}} level={4}>Payment & Security</Title>

          <Paragraph style={{fontFamily: Theme.customFont.custFontNormal}}>
            You can pay with any Visa Debit or Credit card, Mastercard Debit or
            Credit card, Maestro or American Express card. Your payment card
            details will be encrypted to minimise the possibility of
            unauthorised access or disclosure. Authority for payment must be
            given at the time of order. It may be necessary to re-confirm your
            security number for data protection reasons. Payments for products
            will be itemised on your bank statements as showing payment to Desi
            Banter.
          </Paragraph>

          <Paragraph style={{fontFamily: Theme.customFont.custFontNormal}}>
            The purchase on our website will be made in pounds Sterling.
            International credit card providers or banks will determine the
            exchange rate and may add an additional processing or administration
            charge in relation to such payment which holders of international
            credit cards will be liable to pay.
          </Paragraph>

          <Title style={{fontFamily: Theme.customFont.custFontNormal}} level={4}>Liability</Title>

          <Paragraph style={{fontFamily: Theme.customFont.custFontNormal}}>
            To the fullest extent permitted by applicable law, our liability to
            you in respect of an order shall be limited to the value of that
            order, and we shall not be liable to you for any indirect,
            consequential, special or punitive loss, harm or damage (including
            economic loss, loss of profit, loss of revenue, loss of business,
            wasted expenditure or depletion of goodwill) whatsoever or howsoever
            caused, including, without limitation, as a result of our
            negligence. For the avoidance of doubt nothing in these terms and
            conditions excludes our liability in respect of death or personal
            injury caused by our negligence; under section 2(3) of the Consumer
            Protection Act 1987; or for fraud or fraudulent misrepresentation.
          </Paragraph>

          <Paragraph style={{fontFamily: Theme.customFont.custFontNormal}}>
            We use Internet standard encryption technology to protect your data
            when you transmit it to us. When you use your credit card, all your
            data is encrypted and held on a Secure Socket Layer (SSL) server. We
            are constantly updating our technology to provide you with the
            maximum practicable level of security. To check that you are in a
            secure area of our Website look at the bottom right of your internet
            browser and you will see a closed padlock or an unbroken key.
          </Paragraph>

          <Paragraph style={{fontFamily: Theme.customFont.custFontNormal}}>
            If using a public computer or terminal, always close the browser
            when you complete an online session.
          </Paragraph>

          <Paragraph style={{fontFamily: Theme.customFont.custFontNormal}}>
            We will never ask you to confirm any account or credit card details
            via email and if you receive an email claiming to be from
            <Link href="http://www.desibanter.co.uk" target="_blank" underline>
              http://www.desibanter.co.uk
            </Link>{" "}
            asking you to do so then please ignore it and do not respond.
          </Paragraph>
          <Paragraph style={{fontFamily: Theme.customFont.custFontNormal}}>
            Except as expressly set out in these Terms and Conditions, all
            implied warranties and representations (whether implied by statute,
            custom, common law or otherwise) are excluded to the fullest extent
            permitted by law.
          </Paragraph>

          <Title style={{fontFamily: Theme.customFont.custFontNormal}} level={4}>Our Right to Vary these Terms and Conditions</Title>

          <Paragraph style={{fontFamily: Theme.customFont.custFontNormal}}>
            We have the right to revise and amend these Terms and Conditions
            from time to time. You will be subject to the policies and terms and
            conditions in force at the time that you order products from us,
            unless any change to those policies or these Terms and Conditions is
            required to be made by law or governmental authority (in which case
            it will apply to orders previously placed by you), or if we notify
            you of the change to those policies or these Terms and Conditions
            before we send you the dispatch confirmation (in which case we have
            the right to assume that you have accepted the change to the Terms
            and Conditions, unless you notify us to the contrary within 7
            working days of receipt by you of the products).
          </Paragraph>

          <Paragraph style={{fontFamily: Theme.customFont.custFontNormal}}>
            These Terms and Conditions shall be governed by and construed in
            accordance with the laws of England and Wales and any disputes will
            be decided only by the English courts. If any of these Terms and
            Conditions are held by any court of competent authority to be
            unlawful, invalid or unenforceable, in whole or in part, this will
            not affect the validity of the remaining Terms and Conditions which
            will continue to be valid and enforceable to the fullest extent
            permitted by law. Only you and we shall be permitted to enforce
            these Terms and Conditions.
          </Paragraph>

          <Title style={{fontFamily: Theme.customFont.custFontNormal}} level={4}>Customer services</Title>

          <Paragraph style={{fontFamily: Theme.customFont.custFontNormal}}>
            If you have an order query, please email us at{" "}
            <Link href="mailto:info@desibanter.co.uk" underline>
              info@desibanter.co.uk
            </Link>
          </Paragraph>
          
        
          <Paragraph style={{fontFamily: Theme.customFont.custFontNormal}}>Many Thanks</Paragraph>
          <Paragraph style={{fontFamily: Theme.customFont.custFontNormal}}></Paragraph>
          <Paragraph style={{fontFamily: Theme.customFont.custFontNormal}}>Desi Banter</Paragraph>
        

      <Row>
        <Col>
          <Image src={require('../assets/images/logo.jpeg')} alt="Desi Banter Logo" style={{width: 50, height: 50}} />
        </Col>
      </Row>
        </Typography>
      </Col>
    </Row>
  );
};
