import React from "react";
import { Row, Col, Button, Form, Input } from 'antd'

import { UserOutlined, MailOutlined } from '@ant-design/icons'
import Theme from "../utils/Theme";
const { TextArea } = Input
function encode(data) {
    return Object.keys(data)
        .map(key => encodeURIComponent(key) + `=` + encodeURIComponent(data[key]))
        .join(`&`)
}

export const ContactForm = () => {
  const formName = `contact`

  const handleSubmit = (values) => {
      if (values[`bot-field`] === undefined) {
          delete values[`bot-field`]
      }

      fetch(`/`, {
          method: `POST`,
          headers: { 'Content-Type': `application/x-www-form-urlencoded` },
          body: encode({
              'form-name': formName,
              ...values,
          }),
      })
          .then(() => showSuccess())
          .catch(error => showError(error))
  }

  const showSuccess = () => {
      // TODO: Show a success message or navigate to a success page.
      console.log(`form submitted successfully`)
  }

  const showError = (error) => {
      // TODO: Show an error message to the user
      console.log(`There was an error submitting the form`)
      console.log(error)
  }

  return (
      <Row
          justify="center"
      >
          <Col
              xs={23}
              sm={18}
              md={12}
              lg={12}
          >

              {/*
                  This defines how your form is setup for the Netlify bots.
                  Users will not see or interact with this form.
              */}
              <form
                  name={formName}
                  data-netlify="true"
                  data-netlify-honeypot="bot-field"
                  hidden
              >
                  <input type="text" name="name" />
                  <input type="email" name="email" />
                  <textarea name="message"></textarea>
              </form>

              <Form
                  name="cf"
                  method="post"
                  onFinish={handleSubmit}
                  layout="vertical"
              >
                  {/* This is the hidden field that the netlify-honeypot uses. */}
                  <Form.Item
                      label="Don't fill this out"
                      className={`hidden`}
                      style={{ display: `none` }}
                      name="bot-field"
                  >
                      <Input type={`hidden`} />
                  </Form.Item>

                  <Form.Item
                      label="Name"
                      style={{fontFamily: Theme.customFont.custFontNormal}}
                      rules={[{ required: true, message: `Please enter your name.` }]}
                      name="name"
                  >
                      <Input
                          placeholder="Name"
                          style={{fontFamily: Theme.customFont.custFontNormal}}
                          prefix={<UserOutlined className="site-form-item-icon" />}
                      />
                  </Form.Item>

                  <Form.Item
                      label="Email"
                      rules={[{ required: true, type: `email`, message: `Please enter your email.` }]}
                      name="email"
                  >
                      <Input
                          placeholder="Your Email"
                          prefix={<MailOutlined className="site-form-item-icon" />}
                      />
                  </Form.Item>

                  <Form.Item
                      label="Message"
                      rules={[{ required: true, message: `Please enter your message.` }]}
                      name="message"
                  >
                      <TextArea
                          placeholder="Your Message"
                          rows={5}
                      />
                  </Form.Item>

                  <Form.Item>
                      <Button type="primary" htmlType="submit" disabled={false} style={{backgroundColor:'red', fontFamily: Theme.customFont.custFontNormal }}>
                          Send
                      </Button>
                  </Form.Item>
              </Form>
          </Col>
      </Row>
  )
};
