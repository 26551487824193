import React from 'react'
import { Route, Routes } from 'react-router'
import UserProfileDropdown from '../components/UserProfileDropdown'
import { AddtoCart } from '../pages/AddtoCart'
import CancelPage from '../pages/CancelPage'
//import ScrollToTop from '../components/ScrollToTop'
import { ContactUs } from '../pages/ContactUs'
import { Home } from '../pages/Home'
import {Login} from '../pages/Login'
import { MyFavourites } from '../pages/MyFavourites'
import { MyOrders } from '../pages/MyOrders'
import OrderDetails from '../pages/OrderDetails'
import OrderHistoryPage from '../pages/OrderHistoryPage'
import { PrivacyCookie } from '../pages/PrivacyCookie'
import ProductDetailPage from '../pages/ProductDetailPage'
import ProfilePage from '../pages/ProfilePage'
import { ReturnPolicy } from '../pages/ReturnPolicy'
import { ShippingPolicy } from '../pages/ShippingPolicy'
import { SignUp } from '../pages/SignUp'
import { Success } from '../pages/Success'
import SuccessPage from '../pages/SuccessPage'
import { TearmOfService } from '../pages/TearmOfService'
import { TeramsandCondition } from '../pages/TeramsandCondition'


export const AppRoutes = () => {
  return (
    <Routes>
        <Route path='/' element={<Home/>}></Route>
        <Route path='/returnPolicy' element={<ReturnPolicy/>}></Route>
        <Route path='/shippingPolicy' element={<ShippingPolicy/>}></Route>
        <Route path='/tearmsofservice' element={<TearmOfService/>}></Route>
        <Route path='/tearmsandcondition' element={<TeramsandCondition/>}></Route>
        <Route path='/privacyCookies' element={<PrivacyCookie/>}></Route>
        <Route path='/contactUs' element={<ContactUs/>}></Route>
        <Route path='/addtocart' element={<AddtoCart/>}></Route>
        <Route path='/login' element={<Login/>}></Route>
        <Route path='/signup' element={<SignUp/>}></Route>
        <Route element={<UserProfileDropdown/>}></Route>
        <Route path='/myOrder' element={<MyOrders/>}></Route>
        <Route path='/profile' element={<ProfilePage/>}></Route>
        <Route path='/orderHistory' element={<OrderHistoryPage/>}></Route>
        <Route path='/productDetail/:productId' element={<ProductDetailPage/>}></Route>
        <Route path='/myfavourites' element={<MyFavourites/>}></Route>
        <Route path='/success' element={<Success/>}></Route>
        <Route path='/successpage' element={<SuccessPage/>}></Route>
        <Route path='/cancel' element={<CancelPage/>}></Route>
        <Route path='/orderDetail/:orderId' element={<OrderDetails/>}></Route>
    </Routes>
  )
}
