import React from "react";
import { Layout, Row, Col, Divider, List, Space, Typography } from "antd";
import { InstagramFilled } from "@ant-design/icons";
import { SocialIcon } from 'react-social-icons'
import "../App.css";
import { Link } from "react-router-dom";
import Theme from "../utils/Theme";
//import text from 'antd/es/typography/text';
export const Footer = () => {
  const { Footer } = Layout;
  const openTikTokLink = (id) => {
    // Open the TikTok link in a new tab
    if (id === "tiktok") {
      window.open("https://www.tiktok.com/@desi_banter_uk", "_blank");
    } else if (id === "insta") {
      window.open("https://www.instagram.com/desi_banter_org/?igsh=ZzZpZGVyODdscnI3&utm_source=qr", "_blank");
    } else {
      window.open(
        "https://www.facebook.com/desibanteruk",
        "_blank"
      );
    }
  };
  const customer = [
    "Racing car sprays burning fuel into crowd.",
    "Japanese princess to wed commoner.",
    "Australian walks 100km after outback crash.",
    "Man charged over missing wedding girl.",
    "Los Angeles battles huge wildfires.",
  ];
  const customHeaderStyle = {
    border: "none",
    textAlign: "left",
  };
  return (
    <>
      {/* <Footer style={{ borderTop: "1px solid #e8e8e8", padding: 0 }}>
        <Row
          style={{
            height: "80px",
            backgroundColor: "black",
            width: "100%",
            paddingLeft: 20,
            paddingRight: 20,
            flexWrap: "wrap",
          }}
        >
          <Col
            span={24}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img
              src={require("../assets/images/logo.jpeg")}
              alt="Logo"
              style={{
                width: 50,
                height: 50,
                borderRadius: "50%",
                borderWidth: 1,
                borderColor: "transparent",
              }}
            />
            <Typography.Paragraph 
              style={{
                color: "white",
                fontSize: "1.2em",
                marginLeft: "2%",
                marginTop: '10px'
                
                
              }}
            >
              We Design For You The Best Cards For Your Loved Ones.
            </Typography.Paragraph>
          </Col>
        </Row>
      </Footer> */}

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          backgroundColor: "red",
          paddingTop: 20,
        }}
      >
        <img
          src={require("../assets/images/logo.jpeg")}
          alt="Logo"
          style={{
            width: 100,
            height: 100,
            borderRadius: "50%",
            borderWidth: 1,
            borderColor: "transparent",
          }}
        />
      </div>
      <Footer
        style={{
          backgroundColor: "red",
          display: "flex",
          flexDirection: "column",
          paddingBottom:50
        }}
      >
        <Row gutter={[16, 16]} justify="center" style={{ marginTop: 20 }}>
          {/* <Col
            xs={{ span: 12 }}
            sm={{ span: 12 }}
            md={{ span: 6 }}
            lg={{ span: 6 }}
            style={{
              display: "flex",
              justifyContent: "center",
              //alignItems: "center",
              //height: '35vh',
              paddingLeft: 0,
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                //height: '43vh',
                //lineHeight: 2,
              }}
            >
              <Typography.Title
                level={4}
                className="hover-underline-animation"
                style={{ color: "white" }}
              >
                DELIVERY INFO
              </Typography.Title>
              <Typography.Text style={{ color: "white", fontWeight: "bold" }}>
                United Kingdom
              </Typography.Text>

              <text style={{ color: "white", fontSize: "1em" }}>
                1 – 3 working days
              </text>
              <text style={{ color: "white", fontSize: "1em" }}>
                FREE Shipping after £23
              </text>
            </div>
          </Col> */}
          <Col
            xs={{ span: 12 }}
            sm={{ span: 12 }}
            md={{ span: 6 }}
            lg={{ span: 8 }}
            style={{
              display: "flex",
              //justifyContent: "center",
              //alignItems: "center",
              flexDirection: "column",
              //height: "35vh",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width:'fit-content'
                //height: '43vh',
                //lineHeight: 2,
              }}
            >
              <Typography.Title
                level={4}
                className="hover-underline-animation"
                style={{ color: "white", fontFamily: Theme.customFont.custFontNormal }}
              >
                CUSTOMER SERVICE
              </Typography.Title>
              <Link to={"/tearmsandcondition"}>
                <text
                  className="hover-underline"
                  style={{ color: "white", fontSize: "1em", fontFamily: Theme.customFont.custFontNormal }}
                >
                  Terms and Conditions
                </text>
              </Link>
              <Link to={"/returnPolicy"}>
                <text
                  className="hover-underline"
                  style={{ color: "white", fontSize: "1em", fontFamily: Theme.customFont.custFontNormal }}
                >
                  Return & Refund Policy
                </text>
              </Link>
              <Link to={"/tearmsofservice"}>
                <text
                  className="hover-underline"
                  style={{ color: "white", fontSize: "1em", fontFamily: Theme.customFont.custFontNormal }}
                >
                  Terms of Service
                </text>
              </Link>
              <Link to={"/shippingPolicy"}>
                <text
                  className="hover-underline"
                  style={{ color: "white", fontSize: "1em", fontFamily: Theme.customFont.custFontNormal }}
                >
                  Shipping Policy
                </text>
              </Link>
            </div>
          </Col>
          <Col
            xs={{ span: 12 }}
            sm={{ span: 12 }}
            md={{ span: 6 }}
            lg={{ span: 8 }}
            style={{
              display: "flex",
              //justifyContent: "center",
              alignItems: "end",
              flexDirection: "column",
              paddingRight: 0,
              paddingLeft: 0,
              //height: "35vh",
              //flexBasis: "max-content",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width:'fit-content',
                //lineHeight: 2,
                height: "auto", // Set height to 'auto' for responsiveness
              }}
            >
              <Typography.Title
                level={4}
                className="hover-underline-animation"
                style={{ color: "white", width: 'fit-content', fontFamily: Theme.customFont.custFontNormal }}
              >
                CONTACT US
              </Typography.Title>

              <Typography.Text
                className="hover-underline"
                style={{ color: "white", width: 'fit-content', fontFamily: Theme.customFont.custFontNormal }}
              >
                Via our{" "}
                <Link
                  to={"/contactUs"}
                  style={{
                    color: "black",
                    fontSize: "1em",
                    fontWeight: "bold",
                    fontFamily: Theme.customFont.custFontNormal
                  }}
                >
                  Contact Us Page
                </Link>
              </Typography.Text>
              <Typography.Text
                //className="hover-underline"
                style={{
                  color: "white",
                  display: "flex",
                  flexWrap: "wrap",
                  fontStyle: "italic",
                  fontFamily: Theme.customFont.custFontNormal
                  //fontSize: "1em",
                }}
              >
                We aim to get back to you within{" "}
                <span>1 - 3 working days.</span>
              </Typography.Text>
              <Typography.Text style={{ color: "white", fontStyle: "italic", fontFamily: Theme.customFont.custFontNormal }}>
                The best email address to send your query
              </Typography.Text>
              <Typography.Link
                style={{ color: "black", fontWeight: "bold", fontFamily: Theme.customFont.custFontNormal }}
                href="mailto:info@desibanter.co.uk"
              >
                info@desibanter.co.uk
              </Typography.Link>
            </div>
          </Col>
          <Col
            xs={{ span: 12 }}
            sm={{ span: 12 }}
            md={{ span: 6 }}
            lg={{ span: 8 }}
            style={{
              display: "flex",
              //justifyContent: "center",
              alignItems: "end",
              flexDirection: "column",
              //height: "35vh",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                height: "auto", // Set height to 'auto' for responsiveness
                //lineHeight: 2,
              }}
            >
              <Typography.Title
                level={4}
                className="hover-underline-animation"
                style={{ color: "white", fontFamily: Theme.customFont.custFontNormal }}
              >
                SOCIAL LINKS
              </Typography.Title>
              <Typography.Text style={{ color: "white", fontFamily: Theme.customFont.custFontNormal }}>
                <Space
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                  }}
                >
                  <div
                    className="social-icon facebook"
                    onClick={() => openTikTokLink()}
                  >
                    
                    <i className="fa-brands fa-facebook-f" style={{color:'#316FF6'}}></i>
                  </div>
                  {/* <div className="social-icon facebook">
                    <i className="fa-brands fa-twitter"></i>
                  </div> */}
                  <div
                    className="social-icon facebook"
                    onClick={() => openTikTokLink("insta")}
                  >
                    <i className="fa-brands fa-instagram" style={{color: 'red', fontsize: 25}}></i>
                  </div>
                  <div
                    className="social-icon facebook"
                    onClick={() => openTikTokLink("tiktok")}
                  >
                    <i className="fa-brands fa-tiktok" style={{color: '#000', fontsize: 25}}></i>
                  </div>
                  {/* <div className="social-icon facebook">
                    <i className="fa-brands fa-whatsapp"></i>
                  </div> */}
                </Space>
              </Typography.Text>
              <Link to={"/privacyCookies"}>
                <Typography.Text
                  className="hover-underline"
                  style={{ color: "white", fontFamily: Theme.customFont.custFontNormal, paddingTop: 10 }}
                >
                  Privacy and Cookies
                </Typography.Text>
              </Link>
            </div>
          </Col>
        </Row>
      </Footer>
    </>
  );
};
