import React, { useEffect, useState } from 'react';
import { MDBContainer, MDBRow, MDBCol, MDBCard, MDBCardBody, MDBDataTableV5, MDBInput } from 'mdbreact';
import { useSelector } from 'react-redux';
import { Empty } from 'antd';
import Theme from '../utils/Theme';
import { API } from '../api';
import Spin from 'antd/es/spin';
import axios from 'axios';
import { useNavigate } from 'react-router';
import { ReviewModal } from '../components/ReviewModal';

const OrderHistoryPage = () => {
  const { userData } = useSelector((state) => state.auth);
  const [orderHistory, setOrderHistory] = useState(null);
  const [isLoading, setisLoading] = useState(true);
  const [searchText, setSearchText] = useState('');
  const [selectedOrderId, setSelectedOrderId] = useState(null);
  const navigate = useNavigate();
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = (orderId) => {
    setSelectedOrderId(orderId);
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  async function getHistory() {
    try {
      const res = await API.get(`my-orders?customer_id=${userData?.data?._id}`);
      setOrderHistory(res?.data);
    } catch (error) {
      // Handle error
    }
  }

 async function handelAction (id) {
const payload = {
  order_id: id
}
    const response = await axios.post(
      "https://admin.desibanter.co.uk/api/order/pay",
      payload
    );
    if (response?.data && response?.data?.success === true) {
      const url = response?.data?.data?.session?.url
      fetch(url)
    .then((response) => response.json()) // or response.text(), depending on the expected response format
    .then((data) => {
      // Handle the response data
    })
    .catch((error) => {
      console.error('Error fetching data from the URL:', error);
    });
 
  // Navigate after making the fetch request if needed
  window.location.href = url;

  navigate('/success')
  }
  }
  useEffect(() => {
    setTimeout(() => {
      setisLoading(false)
    }, 1500);
    getHistory();
  }, []);

  const columns = [
    { label: 'Order ID', field: 'orderId', width: 100 },
    { label: 'Item Name', field: 'itemName', width: 150 },
    { label: 'Total Amount', field: 'totalAmount', width: 150 },
    { label: 'Shipping Address', field: 'shippingAdress', width: 150 },
    { label: 'Status', field: 'status', width: 100 },
    { label: 'Actions', field: 'actions', width: 100 },
  ];

  const rows = orderHistory?.data
    ?.filter((order) =>
      order.card_items.some((item) => item.title.toLowerCase().includes(searchText.toLowerCase()))
    )
    .map((order) => ({
      orderId: order?.order_count_key,
      itemName: order.card_items.map((item) => item.title).join(', '),
      totalAmount: `£${order.totalprice.toFixed(2)}`,
      shippingAdress: order.billing_address,
      //status: order.status,
      status: order.status == 'pending' 
      ? (
        <button onClick={()=> handelAction(order._id)} style={{ backgroundColor: 'red', color: 'white', padding: '5px 10px', border: 'none' }}>Pay</button>
      ) 
      : order.status == 'paid' 
      ? (
        <span style={{ color: 'green', fontWeight: 'bold' }}>Paid</span>
      )
      : order.status == 'completed' 
      ? (
        <button onClick={() => showModal(order._id)} style={{ backgroundColor: 'red', color: 'white', padding: '5px 10px', borderColor: 'transparent', borderWidth: 1, borderRadius: 5 }}>Review</button>
      ) 
      : 
      (
        <span style={{ color: 'green', fontWeight: 'bold' }}>Dispatched</span>
      ),
      actions: <button onClick={()=> navigate(`/orderDetail/${order._id}`)}  style={{ backgroundColor: 'red', color: 'white', padding: '5px 10px', borderColor: 'transparent', borderWidth: 1, borderRadius: 5 }}>View Details</button>

      
    }));

  const data = {
    columns,
    rows,
  };

  return (
    <>
    {isLoading ? <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', height: '85vh'}}><Spin tip={'Loading...'} size='large' /></div> :  orderHistory?.data.length > 0 ? (
      <MDBContainer className="mt-5 mb-5" style={{ minHeight: '80vh' }}>
        <MDBRow>
          <MDBCol md="12">
            <MDBCard className="bg-white" style={{ backgroundColor: '' }}>
              <MDBCardBody>
                <h2 className="mb-4" style={{ fontFamily: Theme.customFont.custFontNormal }}>
                  Order History
                </h2>
                
                <MDBDataTableV5  responsive hover searchTop entriesOptions={[5, 20, 25]} entries={5} pagesAmount={4} data={data} />
                
              </MDBCardBody>
            </MDBCard>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    ) : (
      <Empty
        style={{
          height: '85vh',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
      />
    )}
  
    <ReviewModal visible={isModalVisible} onClose={handleCancel} orderId={selectedOrderId} customerId={userData?.data?._id} />
    </>
  );
};

export default OrderHistoryPage;