import React, { useEffect, useRef, useState } from "react";
import "../App.css";
import { Outlet, useLocation, useNavigate, useParams, } from "react-router-dom";
import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBCarousel,
  MDBCarouselItem,
  MDBView,
  MDBBtn,
  MDBCardTitle,
  MDBCardText,
  MDBCardHeader,
  MDBCardImage,
  MDBIcon,
  MDBInput,
  MDBListGroup,
  MDBListGroupItem,
  MDBRipple,
  MDBTooltip,
  MDBTypography,
} from "mdb-react-ui-kit";
import {
  Empty,
  InputNumber,
  message,
  Rate,
  Typography,
  Row,
  Col,
  Input,
  Spin,
  Button,
  Modal,
  Image,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  addToCart,
  clearCart,
  removeFromCart,
} from "../redux/counter/CartSlice";
import { addToFav } from "../redux/counter/FavSclice";
//import { Image } from "antd";
import Theme from "../utils/Theme";
import TextArea from "antd/es/input/TextArea";
import { API } from "../api";
import { CalendarOutlined, CarOutlined, BarcodeOutlined } from '@ant-design/icons';
import { PhotoSlider } from 'react-photo-view';
import 'react-photo-view/dist/react-photo-view.css';
import CustomSlider from "../components/CustomSlider";



const ProductDetailPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { productId } = useParams();
  const [selectedImage, setSelectedImage] = useState(null);
  const [checkFav, setcheckFav] = useState(false);
  const [userMessage, setUserMessage] = useState("");
  const [isLoading, setisLoading] = useState(true);
  const [isNavigatedFromHeader, setIsNavigatedFromHeader] = useState(false);
  const [quantity, setQuantity] = useState(1);
  const [item, setItem] = useState();
  const { userData } = useSelector((state) => state.auth);
  const [visible, setVisible] = useState(false);
  const [selectedMedia, setSelectedMedia] = useState(null);

  async function getWishlist() {
    const res = await API.get(
      `wishlist/list?customer_id=${userData?.data?._id}`
    );

    const isFavProduct = res?.data?.data?.some(
      (item) => item?.item_id == productId
    );
    setcheckFav(isFavProduct);
  }

  async function getProduct() {
    try {
      const response = await API.get(`item?item_id=${productId}`);
      setItem(response.data.data);
      setisLoading(false);
    } catch (error) {
      console.error(error, "error");
      // Instead of returning the entire error object, you can handle the error here
    }
  }

  const handleUserMessageChange = (e) => {
    setUserMessage(e.target.value);
    // Update localData as the user types (optional)
    setItem({
      ...item,
      message: e.target.value,
    });
  };
  
 
 
  useEffect(() => {
    // Fetch product data based on productId
    getProduct();
  }, [productId]);
  
  useEffect(() => {
    setSelectedImage(item?.thumbnail);
  }, [item]);


  useEffect(() => {
    if (userData) {
      getWishlist();
    }
  }, []);

  if (!item) {
    return (
      <div
        style={{
          height: "90vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Spin tip={'Loading...'} spinning={isLoading} size="large" />
      </div>
    );
  }

  const handleSubImageClick = (subImage) => {
    // Update the selectedImage state to the clicked sub-image
    setSelectedImage(subImage);
  };

  

  const handleDecrease = () => {
    if (quantity > 1) {
      setQuantity(quantity - 1);
    }
  };

  const handleIncrease = () => {
    // You might want to add a maximum quantity check if needed
    setQuantity(quantity + 1);
  };


  const handleAddToCart = () => {
      const cartItem = {
        ...item,
        number_item: quantity,
      };

      try {
        dispatch(addToCart(cartItem));
        message.success(`${item?.title} has been added to your cart`, 3);
        setQuantity(1);
        
        //navigate('/addtocart')
      } catch (error) {
        console.error("Error dispatching addToCart", error);
      }
  
  };
  
  

  async function addToFavCollection(ele) {
    const payload = {
      customer_id: userData?.data?._id,
      item_id: ele?._id,
    };

    if (checkFav) {
      message.warning(`${ele.title} is already Exist in Favourites`);
    } else {
      try {
        const res = await API.post("wishlist", payload);
        if (res.data.success === true) {
          dispatch(addToFav(ele));
          message.success(`${ele.title} is sucessfully Favourited`);
          setcheckFav(true);
        }
      } catch (error) {
        console.log(error, "fav error");
      }
    }
  }

  const handleMediaClick = (media) => {
    setSelectedMedia(media);
    setVisible(true);
  };

  // Prepare data for PhotoSlider
  const images = item && item.files ? item?.files?.map((media) => {
    if (media && media.endsWith(".mp4")) {
      return { type: "video", src: media, key: media };
    } else if (media) {
      return { type: "image", src: media, key: media };
    } else {
      return null; // Handle the case where media is undefined or null
    }
  }) : [];
  

  return (
    <>
    <MDBContainer  className="my-5" >
      <MDBRow className="justify-content-center">
        <MDBCol lg="12" md="12">
          <MDBCard className="mb-4">
            <MDBCardBody>
              <MDBRow>
                {/* Image Section */}
                <MDBCol lg="6" md="12" className="mb-4 mb-lg-0">
                <MDBRipple
          rippleTag="div"
          rippleColor="light"
          className="bg-image rounded hover-zoom hover-overlay"
          style={{ width: "100%" }}
          
        >
          {selectedImage?.endsWith(".mp4") ? (
            <video controls className="main-image" style={{
              width: "100%",
              height: 300,
              objectFit: "cover",
              cursor: "pointer", // Add cursor pointer to indicate it's clickable
            }}>
              <source src={selectedImage} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          ) : (
            <>
              <img
              preview={false}
              alt="product"
              onClick={()=> setVisible(true)}
              src={selectedImage || item?.thumbnail}
              style={{
                width: "100%",
                height: 300,
                objectFit: "cover",
                cursor: "pointer", // Add cursor pointer to indicate it's clickable
              }}
            />
            <CustomSlider
            images={images}
            visible={visible}
            onClose={() => setVisible(false)}
            
            //index={index}
            //onIndexChange={setIndex}
          />
          
            </>
          )}
        </MDBRipple>
                  <div className="sub-images-container2 mt-3">
                    {item?.files?.map((subImage, subIndex) => (
                      <div
                        key={subIndex}
                        onClick={() => handleSubImageClick(subImage)}
                        className={`sub-image-wrapper `}
                        style={{ marginLeft: subIndex > 0 ? 20 : 0 }}
                      >
                        {subImage?.endsWith(".mp4") ? (
                          <video className="sub-image2">
                            <source src={subImage} type="video/mp4" />
                            Your browser does not support the video tag.
                          </video>
                        ) : (
                          <img
                            alt={`sub-image-${subIndex}`}
                            src={
                              // localData && localData?.images.every(url => url.includes('https://admin.desibanter.co.uk'))
                              //   ? subImage
                              //   : `https://admin.desibanter.co.uk/${subImage}`
                              subImage
                            }
                            style={{cursor:'pointer'}}
                            className="sub-image2"
                          />
                        )}
                      </div>
                    ))}
                  </div>
                </MDBCol>

                {/* Product Details Section */}
                <MDBCol lg="6" md="12">
                  <p style={{ fontFamily: Theme.customFont.custFontNormal }}>
                    <strong>{item?.title}</strong>
                  </p>
                  <Typography.Paragraph
                    ellipsis={{
                      rows: 3,
                      expandable: true,
                      symbol: "more",
                    }}
                    style={{ fontFamily: Theme.customFont.custFontNormal }}
                  >
                    {item?.description}
                  </Typography.Paragraph>
                  {Object.keys(item?.delivery_option || {}).length > 0 && (
  <div>
    <Typography.Paragraph
      style={{ fontFamily: Theme.customFont.custFontNormal, fontWeight: 'bold' }}
    >
      <CalendarOutlined style={{ marginRight: 8 }} /> {`Estimated Delivery Time: ${item?.delivery_option?.description}`}
    </Typography.Paragraph>

    <Typography.Paragraph
      style={{ fontFamily: Theme.customFont.custFontNormal, fontWeight: 'bold' }}
    >
      <CarOutlined style={{ marginRight: 8 }} /> {`Delivery Cost: £${item?.delivery_option?.price}`}
    </Typography.Paragraph>
    <Typography.Paragraph
      style={{ fontFamily: Theme.customFont.custFontNormal, fontWeight: 'bold' }}
    >
    <BarcodeOutlined style={{ marginRight: 8 }}/>  {`Item Code: ${item?.coding}`}
    </Typography.Paragraph>
  </div>
)}

                  <div
                    className="d-flex mb-4 align-items-center"
                    style={{ maxWidth: "150px" }}
                  >
                    <MDBBtn
                      onClick={() => handleDecrease()}
                      className="px-3 me-2"
                      style={{ backgroundColor: "rgb(243, 70, 70)" }}
                    >
                      <MDBIcon fas icon="minus" size="1x" />
                    </MDBBtn>

                    <Input
                      //defaultValue={1}
                      value={quantity}
                      //type="number"
                      //label="Quantity"
                      className="text-center"
                    />

                    <MDBBtn
                      onClick={() => {handleIncrease()
                        quantity  >= item?.quantity - 1 && message.warning(' Item Limit Exceded', 4) }}
                      className="px-3 ms-2"
                      disabled = {quantity >= item?.quantity ? true : false}
                      style={{ backgroundColor: "rgb(243, 70, 70)" }}
                    >
                      <MDBIcon fas icon="plus" size="1x" />
                    </MDBBtn>
                  </div>

                  <p
                    className="text-start"
                    style={{ fontFamily: Theme.customFont.custFontNormal }}
                  >
                    <strong>Price: £{item?.price.toFixed(2)}</strong>
                  </p>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      width: "fit-content",
                    }}
                  >
                    <MDBBtn
                      style={{
                        fontWeight: 600,
                        fontFamily: Theme.customFont.custFontNormal,
                        
                      }}
                      color="danger"
                      onClick={handleAddToCart}
                    >
                      Add to Cart
                    </MDBBtn>
                    {userData ? (
                      <MDBBtn
                        //color="danger"
                        style={{
                          marginTop: 10,
                          fontWeight: 600,
                          fontFamily: Theme.customFont.custFontNormal,
                          backgroundColor: checkFav ? "#000" : "#d1485f",
                        }}
                        onClick={() => addToFavCollection(item)}
                      >
                        <MDBIcon
                          fas
                          icon="heart"
                          size="1x"
                          className="me-2"
                          style={{ color: checkFav ? "red" : "#fff" }}
                        />
                        Add to Favourites
                      </MDBBtn>
                    ) : null}
                    {/* <MDBBtn
                      style={{
                        fontWeight: 'bold',
                        fontFamily: Theme.customFont.custFontNormal,
                        marginTop: 10,
                      }}
                      color="dark"
                      onClick={()=> navigate('/')}
                    >
                      <MDBIcon
                        icon="arrow-right"
                        size="1x"
                        className="me-2"
                        style={{ color: "#fff" }}
                        />
                      Countinue Shopping
                      
                    </MDBBtn> */}
                  </div>
                  <Typography.Title
                    level={5}
                    style={{
                      marginTop: 10,
                      fontFamily: Theme.customFont.custFontNormal,
                    }}
                  >
                    Add a custom message
                  </Typography.Title>
                  <div style={{ marginTop: 10 }}>
                    <TextArea
                      value={userMessage}
                      onChange={handleUserMessageChange}
                      placeholder="Type your message here"
                      autoSize={{ minRows: 2, maxRows: 6 }}
                      style={{
                        marginBottom: 10,
                        fontFamily: Theme.customFont.custFontNormal,
                      }}
                    />
                  </div>
                </MDBCol>
              </MDBRow>

              {/* <hr className="my-4" /> */}
              {/* <Typography.Title
                level={3}
                style={{
                  paddingLeft: 5,
                  fontFamily: Theme.customFont.custFontNormal,
                }}
              >
                Reviews
              </Typography.Title> */}
              {/* <MDBRow className="mb-0">
                <MDBCol md="12" xl="10">
                  <MDBCard className="shadow-0 border rounded-3 mt-5 mb-3">
                    <MDBCardBody>
                      <MDBRow>
                        <MDBCol md="9">
                          <Rate allowHalf defaultValue={3.5} />
                        
                          <h5
                            style={{
                              fontFamily: Theme.customFont.custFontNormal,
                            }}
                          >
                            Quant trident shirts
                          </h5>
                          
                          <p
                            className=" mb-4 mb-md-0"
                            style={{
                              fontFamily: Theme.customFont.custFontNormal,
                            }}
                          >
                            There are many variations of passages of Lorem Ipsum
                            available, but the majority have suffered alteration
                            in some form, by injected humour, or randomised
                            words which don't look even slightly believable.
                          </p>
                        </MDBCol>
                      </MDBRow>
                    </MDBCardBody>
                  </MDBCard>
                </MDBCol>
              </MDBRow> */}
            </MDBCardBody>
          </MDBCard>
        </MDBCol>
        
      </MDBRow>
    </MDBContainer>
    
    </>
  );
};
export default ProductDetailPage;
